import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Initial state with empty arrays and default price range
const initialState = {
  userInfo: [],
  products: [],
  checkedBrands: [],
  checkedCategorys: [],
  minPrice: 0,
  maxPrice: 10000,
  checkedColors: [], // List of selected colors
};

// Utility function to sanitize color data
const sanitizeColorData = (color) => ({
  id: color.id || null,
  name: color.name || '',
  createdAt: color.createdAt || new Date().toISOString(),
  updatedAt: color.updatedAt || new Date().toISOString(),
});

// Utility function to normalize checked colors (ensure unique entries)
const normalizeCheckedColors = (colors) => {
  const colorNames = new Set();
  return colors.filter(color => {
    const sanitizedColor = sanitizeColorData(color);
    if (!colorNames.has(sanitizedColor.name)) {
      colorNames.add(sanitizedColor.name);
      return true;
    }
    return false;
  });
};

export const orebiSlice = createSlice({
  name: "Vintara",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.products.find(
        (item) => item._id === action.payload._id
      );
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }
      toast.success("Product added to cart");
    },
    increaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => item._id === action.payload._id
      );
      if (item) {
        item.quantity++;
      }
    },
    decreaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => item._id === action.payload._id
      );
      if (item && item.quantity > 1) {
        item.quantity--;
      }
    },
    deleteItem: (state, action) => {
      state.products = state.products.filter(
        (item) => item._id !== action.payload
      );
      toast.error("Product removed from cart");
    },
    resetCart: (state) => {
      state.products = [];
    },

    toggleBrand: (state, action) => {
      const brand = action.payload;
      const isBrandChecked = state.checkedBrands.some(
        (b) => b._id === brand._id
      );

      if (isBrandChecked) {
        state.checkedBrands = state.checkedBrands.filter(
          (b) => b._id !== brand._id
        );
      } else {
        state.checkedBrands.push(brand);
      }
    },

    toggleCategory: (state, action) => {
      const category = action.payload;
      const isCategoryChecked = state.checkedCategorys.some(
        (b) => b._id === category._id
      );

      if (isCategoryChecked) {
        state.checkedCategorys = state.checkedCategorys.filter(
          (b) => b._id !== category._id
        );
      } else {
        state.checkedCategorys.push(category);
      }
    },

    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },

    toggleColor: (state, action) => {
      const color = sanitizeColorData(action.payload); // Sanitize the color data
      const existingColorIndex = state.checkedColors.findIndex(c => c.name === color.name);

      if (existingColorIndex !== -1) {
        // Remove color if it already exists
        state.checkedColors.splice(existingColorIndex, 1);
      } else {
        // Add new color
        state.checkedColors.push(color);
      }

      // Normalize checked colors to ensure uniqueness
      state.checkedColors = normalizeCheckedColors(state.checkedColors);

      console.log("Updated Checked Colors:", JSON.stringify(state.checkedColors));
    },
  },
});

export const {
  addToCart,
  increaseQuantity,
  decreaseQuantity,
  deleteItem,
  resetCart,
  toggleBrand,
  toggleCategory,
  setMinPrice,
  setMaxPrice,
  toggleColor,
} = orebiSlice.actions;

export default orebiSlice.reducer;
