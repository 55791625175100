import React, { useState } from "react";
import {
  FaDollarSign,
  FaChartLine,
  FaShoppingCart,
  FaSearch,
} from "react-icons/fa";
import { Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Sales = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Sample data for charts
  const salesTrendData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Monthly Sales",
        data: [12000, 19000, 3000, 5000, 20000, 30000, 45000],
        fill: false,
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        tension: 0.4,
      },
    ],
  };

  const productDistributionData = {
    labels: ["Laptop_bags", "Travelling", "Tote", "School", "Men"],
    datasets: [
      {
        label: "Product Categories",
        data: [25, 15, 30, 10, 20],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const lineOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  const pieOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      {/* Analytics Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <div className="bg-white shadow rounded-lg p-4 flex items-center justify-between md:justify-around">
          <FaDollarSign className="text-4xl text-green-500" />
          <div className="text-right">
            <p className="text-gray-700 text-xl font-bold">Total Sales</p>
            <p className="text-3xl font-semibold text-green-600">KES 250,000</p>
          </div>
        </div>
        <div className="bg-white shadow rounded-lg p-4 flex items-center justify-between md:justify-around">
          <FaChartLine className="text-4xl text-blue-600" />
          <div className="text-right">
            <p className="text-gray-700 text-xl font-bold">Monthly Growth</p>
            <p className="text-3xl font-semibold text-blue-600">15%</p>
          </div>
        </div>
        <div className="bg-white shadow rounded-lg p-4 flex items-center justify-between md:justify-around">
          <FaShoppingCart className="text-4xl text-purple-500" />
          <div className="text-right">
            <p className="text-gray-700 text-xl font-bold">Total Orders</p>
            <p className="text-3xl font-semibold text-purple-600">150</p>
          </div>
        </div>
      </div>

      {/* Sales Trends and Distribution Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="bg-white shadow rounded-lg p-4 h-80">
          <h2 className="text-xl font-bold text-gray-700 mb-4">Sales Trend</h2>
          <Line data={salesTrendData} options={lineOptions} />
        </div>
        <div className="bg-white shadow rounded-lg p-4 h-80">
          <h2 className="text-xl font-bold text-gray-700 mb-4">
            Product Distribution
          </h2>
          <Pie data={productDistributionData} options={pieOptions} />
        </div>
      </div>

      {/* Search Bar */}
      <div className="mb-4 flex items-center justify-start mt-9">
        <div className="relative w-full max-w-md md:w-72">
          <input
            type="text"
            placeholder="Search sales..."
            className="w-full p-2 pl-10 border border-gray-300 rounded-lg 
            focus:outline-none focus:ring-1 focus:ring-orange-600 outline-1"
            value={searchTerm}
            onChange={handleSearch}
          />
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
        </div>
      </div>

      {/* Sales Table */}
      <div className="bg-white shadow-lg rounded-lg overflow-x-auto">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Order ID
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Customer Name
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Product
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Date
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                #O123456
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                John Doe
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                Laptop bag
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                2024-08-01
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                KES 1,200.00
              </td>
            </tr>
            {/* Additional rows here */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Sales;
