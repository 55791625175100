import React, { useEffect, useState } from "react";
import { ImPlus } from "react-icons/im";
import NavTitle from "./NavTitle";
import { useDispatch, useSelector } from "react-redux";
import { toggleCategory } from "../../../../redux/orebiSlice";
import useSession from "../../../../context/useSession";
import axios from "axios";
import { motion } from "framer-motion";

const Category = () => {
  const [showSubCatOne, setShowSubCatOne] = useState(true);
  const [showCategory, setShowCategory] = useState(true);
  const [categories, setCategories] = useState([]);
  const { authToken } = useSession();
  const dispatch = useDispatch();

  const checkedCategories = useSelector(
    (state) => state.orebiReducer.checkedCategorys
  );

  const handleToggleCategory = (category) => {
    dispatch(toggleCategory(category));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoriesResponse = await fetchCategories();
      setCategories(categoriesResponse.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCategories = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/categories`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  return (
    <div className="w-full">
      <div
        onClick={() => setShowCategory(!showCategory)}
        className="cursor-pointer"
      >
        <NavTitle title="Shop by Category" icons={true} />
      </div>
      {showCategory && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
            {categories.map((item) => (
              <li
                key={item.id}
                className={`border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 duration-300 p-1 rounded-lg px-2 ${
                  checkedCategories.some((b) => b.id === item.id)
                    ? "text-primeColor bg-orange-100 border-gray-400"
                    : "hover:text-primeColor hover:border-gray-400"
                }`}
              >
                <input
                  type="checkbox"
                  id={item.id}
                  checked={checkedCategories.some((b) => b.id === item.id)}
                  onChange={() => handleToggleCategory(item)}
                />
                {item.name}
                {item.icons && (
                  <span
                    onClick={() => setShowSubCatOne(!showSubCatOne)}
                    className="text-[10px] lg:text-xs cursor-pointer text-gray-400 hover:text-primeColor duration-300"
                  >
                    <ImPlus />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Category;
