import React from "react";
import { Link } from "react-router-dom";

import ShopNow from "../../designLayouts/buttons/ShopNow";
import medication from "../../../assets/advert/advert1.png";
import skincare from "../../../assets/advert/advert2.png";
import personalcare from "../../../assets/advert/advert3.png";
import supplements from "../../../assets/advert/advert4.png"; // Added image for supplements

const Sale = () => {
  return (
    <div className="min-h-[70vh] py-4 px-4 lg:px-10 flex flex-col md:flex-row items-stretch justify-between gap-8 lg:gap-12">
      {/* Left Column with Two Components */}
      <div className="w-full md:w-2/3 lg:w-1/2 flex flex-col gap-6">
        {/* Prescription Medications Section */}
        <div className="group bg-purple-200 shadow-lg flex-1 flex flex-col justify-between p-6 rounded-xl border border-blue-200">
          <div className="w-full flex-grow flex flex-col justify-center">
            <img
              src={medication}
              className="h-32 md:h-48 lg:h-56 w-full object-contain mb-6 transition-all duration-1000 ease-in-out group-hover:scale-110"
              alt="Prescription Medications"
            />
            <div className="text-left w-full">
              <h2 className="text-3xl md:text-4xl font-bold mb-4 text-purple-900 leading-snug">
                Prescription Medications
              </h2>
              <p className="text-base md:text-lg lg:text-xl mb-6 text-gray-700">
                Get the right medications quickly and easily.
              </p>
              <Link to="/shop/medications" className="inline-block">
                <ShopNow />
              </Link>
            </div>
          </div>
        </div>

        {/* Supplements Section (New Component) */}
        <div className="group bg-purple-200 shadow-lg flex-1 flex flex-col justify-between p-6 rounded-xl border border-purple-200">
          <div className="w-full flex-grow flex flex-col justify-center">
            <img
              src={supplements}
              className="h-32 md:h-48 lg:h-56 w-full object-contain mb-6 transition-all duration-1000 ease-in-out group-hover:scale-110"
              alt="Supplements"
            />
            <div className="text-left w-full">
              <h2 className="text-3xl md:text-4xl font-bold mb-4 text-purple-900 leading-snug">
                Health Supplements
              </h2>
              <p className="text-base md:text-lg lg:text-xl mb-6 text-gray-700">
                Boost your health with essential supplements.
              </p>
              <Link to="/shop/supplements" className="inline-block">
                <ShopNow />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Right Column with Two Components */}
      <div className="w-full md:w-2/3 lg:w-1/2 flex flex-col gap-6">
        {/* Skincare Section */}
        <div className="group bg-green-100 shadow-lg flex-1 flex flex-col justify-between p-6 rounded-xl border border-green-200">
          <div className="w-full flex-grow flex flex-col justify-center">
            <img
              src={skincare}
              className="h-48 md:h-56 w-full object-contain mb-6 transition-all duration-1000 ease-in-out group-hover:scale-110"
              alt="Skincare Products"
            />
            <div className="text-left w-full">
              <h2 className="text-3xl md:text-4xl font-bold mb-4 text-green-900 leading-snug">
                Skincare Essentials
              </h2>
              <p className="text-base md:text-lg lg:text-xl mb-6 text-gray-700">
                Pamper your skin with our best care products.
              </p>
              <Link
                to="/shop/skincare"
                className="inline-block  group-hover:scale-100 duration-300 ease-in-out"
              >
                <ShopNow />
              </Link>
            </div>
          </div>
        </div>

        {/* Personal Care Section */}
        <div className="group bg-green-100 shadow-lg flex-1 flex flex-col justify-between p-6 rounded-xl border border-teal-200">
          <div className="w-full flex-grow flex flex-col justify-center">
            <img
              src={personalcare}
              className="h-48 md:h-56 w-full object-contain mb-6 transition-all duration-1000 ease-in-out group-hover:scale-110"
              alt="Personal Care Products"
            />
            <div className="text-left w-full">
              <h2 className="text-3xl md:text-4xl font-bold mb-4 text-green-900 leading-snug">
                Personal Care Products
              </h2>
              <p className="text-base md:text-lg lg:text-xl mb-6 text-gray-700">
                Everything you need for daily self-care.
              </p>
              <Link to="/shop/personalcare" className="inline-block">
                <ShopNow />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sale;
