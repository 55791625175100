import React, { useContext } from "react";
import { CartContext } from "../../context/cart/CartProvider ";
import { ImCross } from "react-icons/im";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { toast } from "react-toastify";
import ContactBottom from "../../components/home/Header/Components/ContactBottom";

const WishList = () => {
  const { wishListItems, removeItemFromWishList } = useContext(CartContext);

  const handleItemDelete = async (itemId) => {
    try {
      await removeItemFromWishList(itemId);
      toast.success("Item removed from wishlist successfully");
    } catch (error) {
      console.error(
        "Error deleting item:",
        error.response?.data?.message || error.message
      );
      toast.error(error.response?.data?.message || "Failed to delete item.");
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      <Breadcrumbs title="Wishlist" />

      <div className="pt-5 pb-10">
        <h2 className="text-purple-950 font-bold text-3xl text-center">My WishList</h2>
      </div>

      <div className="w-full flex items-center justify-between text-white font-semibold bg-purple-950 text-primeColor px-6 py-2 rounded-lg shadow-lg mb-4">
        <h2 className="col-span-2">Product</h2>
        <h2 className="text-center">Price</h2>
      </div>

      <div className="space-y-2 pb-5">
        {Array.isArray(wishListItems) && wishListItems.length > 0 ? (
          wishListItems.map((wishlistItem) => (
            <div
              key={wishlistItem.id}
              className="flex items-center justify-between p-4 bg-gray-100 border-b transition-transform transform"
            >
              <div className="flex items-center gap-4">
                <ImCross
                  onClick={() => handleItemDelete(wishlistItem.id)}
                  className="text-pink-600 hover:text-pink-700 duration-300 transition-all cursor-pointer"
                />
                <div>
                  <a
                    href={`/product/${wishlistItem.productId}`}
                    className="font-titleFont hover:underline font-semibold text-sm text-gray-700"
                  >
                    {wishlistItem.product.name}
                  </a>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <span className="text-sm font-semibold text-gray-600">
                  KES {wishlistItem.product.price}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-10 text-gray-500">
            <h3 className="text-xl">Your wishlist is currently empty!</h3>
            <p>Browse our products and add items to your wishlist.</p>
          </div>
        )}
      </div>
      <ContactBottom />
    </div>
  );
};

export default WishList;
