import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  TablePagination,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useSession from "../../../context/useSession";
import moment from "moment";

const Prescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [status, setStatus] = useState("");
  const { authToken } = useSession();

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchPrescriptions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/others/get_prescriptions`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setPrescriptions(response.data.prescriptions);
      } catch (error) {
        console.error("Error fetching prescriptions:", error);
      }
    };
    fetchPrescriptions();
  }, [authToken]);

  const openImageModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeImageModal = () => {
    setModalIsOpen(false);
    setSelectedImage("");
  };

  const openEditModal = (prescription) => {
    setSelectedPrescription(prescription);
    setStatus(prescription.status || "");
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedPrescription(null);
    setStatus("");
  };

  const handleUpdateStatus = async () => {
    if (!selectedPrescription) return;
    try {
      await axios.put(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/others/edit_prescriptions/${selectedPrescription.id}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setPrescriptions((prev) =>
        prev.map((prescription) =>
          prescription.id === selectedPrescription.id
            ? { ...prescription, status }
            : prescription
        )
      );
      closeEditModal();
    } catch (error) {
      console.error("Error updating prescription status:", error);
    }
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="max-w-7xl mx-auto p-6 py-10">
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        className="font-extrabold text-gray-900 mb-8"
        style={{ fontFamily: "'Poppins', sans-serif" }}
      >
        Available Prescriptions
      </Typography>
      <TableContainer className="shadow-2xl rounded-lg overflow-hidden">
        <Table className="min-w-full divide-y divide-gray-200 bg-white">
          <TableHead className="bg-gray-100">
            <TableRow>
              {[
                "Full Name",
                "Location",
                "Contact Number",
                "Prescription Image",
                "Status",
                "Date",
                "Actions",
              ].map((head) => (
                <TableCell key={head} className="text-center font-bold py-3">
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {prescriptions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((prescription) => (
                <TableRow
                  key={prescription.id}
                  className="hover:bg-gray-50 transition ease-in-out duration-300"
                >
                  <TableCell className="text-center">
                    {prescription.fullName}
                  </TableCell>
                  <TableCell className="text-center">
                    {prescription.location}
                  </TableCell>
                  <TableCell className="text-center">
                    {prescription.contactNumber}
                  </TableCell>
                  <TableCell className="text-center">
                    <CardMedia
                      component="img"
                      className="w-12 h-12 cursor-pointer rounded-md object-cover shadow-lg"
                      image={prescription.prescriptionImage}
                      alt={prescription.fullName}
                      onClick={() =>
                        openImageModal(prescription.prescriptionImage)
                      }
                    />
                  </TableCell>
                  <TableCell className="text-center">
                    <span className="bg-purple-950 text-white rounded px-1 py-1">
                      {prescription.status}
                    </span>
                  </TableCell>
                  <TableCell className="text-center">
                    {moment(prescription.createdAt).format(
                      "MMMM Do YYYY, h:mm A"
                    )}
                  </TableCell>
                  <TableCell className="text-center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => openEditModal(prescription)}
                      className="rounded-md"
                      style={{ backgroundColor: "#22c55e", color: "#fff" }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={prescriptions.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>

      {/* Image Modal */}
      <Modal open={modalIsOpen} onClose={closeImageModal}>
        <Card className="p-6 bg-white rounded-xl shadow-2xl max-w-lg mx-auto mt-24">
          <CardContent>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              className="font-semibold mb-4"
            >
              Prescription Image
            </Typography>
            <img
              src={selectedImage}
              alt="Prescription"
              className="w-full h-auto rounded-md"
            />
            <button
              onClick={closeImageModal}
              className="mt-4 rounded-md p-2"
              style={{ backgroundColor: "#db2777", color: "#fff" }}
            >
              Close
            </button>
          </CardContent>
        </Card>
      </Modal>

      {/* Edit Status Modal */}
      <Modal open={editModalIsOpen} onClose={closeEditModal}>
        <Card className="p-6 bg-white rounded-xl shadow-2xl max-w-lg mx-auto mt-24">
          <CardContent>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              className="font-semibold mb-4"
            >
              Edit Prescription Status
            </Typography>
            <FormControl fullWidth variant="outlined" className="mb-4">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="">
                  <em>Select Status</em>
                </MenuItem>
                <MenuItem value="Viewed">Viewed</MenuItem>
                <MenuItem value="Dispatched">Dispatched</MenuItem>
                <MenuItem value="Delivered">Delivered</MenuItem>
              </Select>
            </FormControl>
            <div className="flex justify-between mt-4">
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateStatus}
                className="rounded-md"
                style={{ backgroundColor: "#3b0764", color: "#fff" }}
              >
                Update Status
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={closeEditModal}
                className="rounded-md"
                style={{ borderColor: "#db2777", color: "#ff5757" }}
              >
                Close
              </Button>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};

export default Prescriptions;
