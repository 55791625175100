import React, { useState } from "react";
import Slider from "react-slick";
import medOne from "../../assets/landing/newsletter.png";
import medTwo from "../../assets/landing/home2.png";
import medThree from "../../assets/landing/home3.png";

const CustomSlide = ({ Subtext, imgSrc, text, buttonLink, buttonText }) => (
  <div
    style={{
      position: "relative",
      backgroundColor: "#3b0764",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      flexDirection: window.innerWidth < 768 ? "column" : "row",
    }}
  >
    <div
      style={{
        maxWidth: window.innerWidth < 768 ? "100%" : "500px",
        marginRight: window.innerWidth < 768 ? "0" : "100px",
        textAlign: window.innerWidth < 768 ? "center" : "left",
      }}
    >
      <h1
        style={{
          marginBottom: "15px",
          fontSize: window.innerWidth < 768 ? "1.8rem" : "2.5rem",
          color: "#fff",
          fontWeight: "700",
        }}
      >
        {text}
      </h1>
      <p
        style={{
          marginBottom: "25px",
        }}
        className="text-gray-100 text-sm"
      >
        {Subtext}
      </p>

      <a href={buttonLink}>
        <button className="bg-green-500 text-white text-lg font-bodyFont w-full h-[50px] hover:bg-green-600 duration-300 font-bold rounded">
          {buttonText}
        </button>
      </a>
    </div>
    <div
      style={{
        marginLeft: window.innerWidth < 768 ? "0" : "100px",
        marginTop: window.innerWidth < 768 ? "20px" : "0",

        alignSelf: "flex-end",
      }}
    >
      <img
        src={imgSrc}
        alt={text}
        style={{
          width: "100%",
          height: window.innerWidth < 768 ? "300px" : "400px",
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
    </div>
  </div>
);

const Banner = () => {
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: window.innerWidth < 768 ? "5%" : "7%",
          transform: "translateY(-50%)",
          display: "none",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: window.innerWidth < 768 ? "20px" : "30px",
                color: "#22c55e", // Green-500 for active dot
                borderRight: "4px #22c55e solid", // Green-500
                padding: window.innerWidth < 768 ? "5px 0" : "8px 0",
                cursor: "pointer",
              }
            : {
                width: window.innerWidth < 768 ? "20px" : "30px",
                color: "transparent",
                borderRight: "4px white solid",
                padding: window.innerWidth < 768 ? "5px 0" : "8px 0",
                cursor: "pointer",
              }
        }
      >
        0{i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          appendDots: (dots) => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "5%",
                transform: "translateY(-50%)",
                display: "none",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? {
                      width: "25px",
                      color: "#22c55e",
                      borderRight: "3px #22c55e solid",
                      padding: "5px 0",
                      cursor: "pointer",
                    }
                  : {
                      width: "25px",
                      color: "transparent",
                      borderRight: "3px white solid",
                      padding: "5px 0",
                      cursor: "pointer",
                    }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
    ],
  };

  const slides = [
    {
      imgSrc: medOne,
      text: "Trusted Medicine Delivered Fast",
      Subtext:
        "Get access to a wide variety of trusted medicines delivered directly to your door with top-notch service.",
      buttonLink: "/shop",
      buttonText: "Shop Medicines",
    },
    {
      imgSrc: medTwo,
      text: "Skincare Essentials for Healthy Skin",
      Subtext:
        "Explore our collection of skincare products that provide the perfect balance between beauty and care.",
      buttonLink: "/shop",
      buttonText: "Shop Skincare",
    },
    {
      imgSrc: medThree,
      text: "Medical Supplies You Can Trust",
      Subtext:
        "From surgical masks to healthcare tools, find everything you need for a safe and healthy environment.",
      buttonLink: "/shop",
      buttonText: "Shop Medical Supplies",
    },
  ];

  return (
    <div className="w-full bg-[#3b0764] pt-4 pb-4">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <CustomSlide key={index} {...slide} />
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
