import React, { useContext, useState } from "react";
import { BsCheckCircleFill, BsArrowRepeat } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import useSession from "../../../context/useSession";
import { SessionContext } from "../../../context/SessionContext";
import {
  FaEnvelope,
  FaWhatsapp,
  FaQuestionCircle,
  FaPhone,
} from "react-icons/fa";

import { notification } from "antd";

const AdminLogin = () => {
  const { login, errorMsg, successMsg, loading } = useSession();
  const { user } = useContext(SessionContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
      return;
    }

    if (!password) {
      setErrPassword("Enter your password");
      return;
    }

    const success = await login(email, password);
    if (success) {
      navigate(success.isUser ? "/" : "/admin");
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-start pt-6 pb-6 bg-gray-100">
      <div className="w-full lgl:w-[500px] h-full flex flex-col justify-start">
        {successMsg ? (
          <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signup">
              <button className="w-full bg-gradient-to-r from-blue-700 to-blue-800 text-white py-4 rounded-lg hover:bg-gradient-to-l hover:from-blue-800 hover:to-blue-900 transition duration-300">
                Sign Up
              </button>
            </Link>
          </div>
        ) : (
          <form
            className="w-full lgl:w-[450px] mt-2 mb-5 md:mt-0 md:mb-0 flex items-center justify-center"
            onSubmit={handleSignIn}
          >
            <div className="px-6 py-4 w-full h-[80%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
              <h1 className="font-titleFont font-semibold text-base mdl:text-xl mb-10">
                Sign in to continue shopping.
              </h1>
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-12 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400 outline-orange-500"
                    type="email"
                    placeholder="Email address"
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handlePassword}
                    value={password}
                    className="w-full h-12 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400 outline-orange-500"
                    type="password"
                    placeholder="Password"
                  />
                  {errPassword && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errPassword}
                    </p>
                  )}
                </div>

                {errorMsg && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errorMsg}
                  </p>
                )}

                <button
                  type="submit"
                  className={
                    "w-full bg-gradient-to-r from-green-500 to-green-500 text-white py-2 rounded-lg hover:bg-gradient-to-l hover:from-green-600 hover:to-green-600 transition-all duration-500 flex items-center justify-center"
                  }
                  disabled={loading}
                >
                  {loading ? (
                    <BsArrowRepeat className="animate-spin mr-2" />
                  ) : (
                    "Sign In"
                  )}
                </button>

                <p className="text-sm text-center font-titleFont font-normal">
                  Forgot password?{" "}
                  <a href="/reset_password">
                    <span className="text-pink-700 hover:text-pink-800 duration-500 transition-all">
                      Reset
                    </span>
                  </a>
                </p>

                <p className="text-sm text-center font-titleFont font-normal">
                  Don't have an Account?{" "}
                  <Link to="/signup">
                    <span className="text-pink-700 hover:text-pink-800 duration-500 transition-all">
                      Sign up
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="pt-10 pb-10 w-3/4 flex flex-col md:flex-row items-center justify-around gap-8">
        {/* Email Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="mailto:support@example.com"
            className="flex items-center gap-4"
          >
            <FaEnvelope className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">
                Send us a message
              </span>
            </div>
          </a>
        </div>
        {/* WhatsApp Option */}
        <div className="bg-purple-900  backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="https://wa.me/1234567890"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-4"
          >
            <FaWhatsapp className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Chat with us</span>
            </div>
          </a>
        </div>
        {/* FAQ Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <Link to="/faq" className="flex items-center gap-4">
            <FaQuestionCircle className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Help & FAQ</span>
              <span className="text-white text-sm">Find answers quickly</span>
            </div>
          </Link>
        </div>
        {/* Call Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a href="tel:+1234567890" className="flex items-center gap-4">
            <FaPhone className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Contact us</span>
              <span className="text-white text-sm">+1 234 567 890</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
