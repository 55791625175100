import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useSession from "../../../context/useSession";

const steps = ["Step 1", "Step 2"];

const RegisterProduct = () => {
  const { authToken } = useSession();

  const [activeStep, setActiveStep] = useState(0);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [condition, setConditions] = useState([]);
  const [unit, setUnits] = useState([]);
  const [formState, setFormState] = useState({
    category: "",
    brand: "",
    condition: "",
    unit: "",
    productName: "",
    productDescription: "",
    instructions: "",
    price: "",
    count: "",
    measurement: "",
    images: [],
    hasInstructions: false,
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const categoriesRes = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/categories`,
          { headers }
        );
        setCategories(categoriesRes.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategories();
    const intervalId = setInterval(fetchCategories, 100000);
    return () => clearInterval(intervalId);
  }, [authToken]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const brandsRes = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/colors`,
          { headers }
        );
        setBrands(brandsRes.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBrands();
    const intervalId = setInterval(fetchBrands, 100000);
    return () => clearInterval(intervalId);
  }, [authToken]);

  useEffect(() => {
    const fetchConditions = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const conditionsRes = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/sizes`,
          { headers }
        );
        setConditions(conditionsRes.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchConditions();
    const intervalId = setInterval(fetchConditions, 100000);
    return () => clearInterval(intervalId);
  }, [authToken]);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const unitRes = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/units`,
          { headers }
        );
        setUnits(unitRes.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUnits();
    const intervalId = setInterval(fetchUnits, 100000);
    return () => clearInterval(intervalId);
  }, [authToken]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + formState.images.length > 4) {
      Swal.fire({
        title: "Limit Exceeded!",
        text: "You can only upload a maximum of 4 images.",
        icon: "warning",
        confirmButtonText: "Okay",
      });
      return;
    }
    const newImages = files.map((file) => URL.createObjectURL(file));
    setFormState((prevState) => ({
      ...prevState,
      images: [...prevState.images, ...files],
    }));
  };

  const removeImage = (index) => {
    setFormState((prevState) => ({
      ...prevState,
      images: prevState.images.filter((_, i) => i !== index),
    }));
  };

  const onSubmit = async () => {
    try {
      const formData = new FormData();

      formData.append("productName", formState.productName);
      formData.append("productDescription", formState.productDescription);
      formData.append(
        "instructions",
        formState.hasInstructions ? formState.instructions : false
      );
      formData.append("categoryId", formState.category);
      formData.append("colorId", formState.brand);
      formData.append("sizeId", formState.condition);
      formData.append("unitId", formState.unit);
      formData.append("price", formState.price);
      formData.append("count", formState.count);
      formData.append("measurement", formState.measurement);

      // Append each image to FormData
      formState.images.forEach((image) => {
        formData.append("images", image); // Use 'images' for multiple uploads
      });

      const response = await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/create_product`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      Swal.fire({
        title: "Success!",
        text: "Product registered successfully.",
        icon: "success",
        confirmButtonText: "Okay",
      }).then(() => {
        window.location.href = "/admin";
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to register product. Please try again.",
        icon: "error",
        confirmButtonText: "Okay",
      });
      console.error("Error registering product:", error);
    }
  };

  return (
    <div className="bg-[#F5F5F3] py-8 px-4">
      <div className="max-w-4xl mx-auto bg-white p-8 shadow-xl rounded-lg">
        <div className="flex mb-8">
          {steps.map((label, index) => (
            <div
              key={label}
              className={`flex-1 text-center ${
                activeStep === index ? "font-bold" : ""
              }`}
            >
              {label}
              {index < steps.length - 1 && (
                <div className="inline-block w-1 h-4 bg-gray-400 mx-2"></div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-8">
          {activeStep === 0 && (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4">
                {/* Product Name */}
                <div>
                  <label className="block text-gray-700 font-medium mb-1">
                    Product Name
                  </label>
                  <input
                    type="text"
                    name="productName"
                    value={formState.productName}
                    onChange={handleChange}
                    placeholder="Enter product name"
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>

                {/* Category */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2">
                    Category
                  </label>
                  <select
                    name="category"
                    value={formState.category}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Brand */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2">
                    Brand
                  </label>
                  <select
                    name="brand"
                    value={formState.brand}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value="">Select Brand</option>
                    {brands.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Condition */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2">
                    Condition
                  </label>
                  <select
                    name="condition"
                    value={formState.condition}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value="">Select Condition</option>
                    {condition.map((condition) => (
                      <option key={condition.id} value={condition.id}>
                        {condition.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Unit */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2">
                    Unit
                  </label>
                  <select
                    name="unit"
                    value={formState.unit}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value="">Select Unit</option>
                    {unit.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Price */}
                <div>
                  <label className="block text-gray-700 font-medium mb-1">
                    Price
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={formState.price}
                    onChange={handleChange}
                    placeholder="Enter price"
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>

                {/* Count */}
                <div>
                  <label className="block text-gray-700 font-medium mb-1">
                    Count
                  </label>
                  <input
                    type="number"
                    name="count"
                    value={formState.count}
                    onChange={handleChange}
                    placeholder="Enter count"
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>

                {/* Measurement */}
                <div>
                  <label className="block text-gray-700 font-medium mb-1">
                    Measurement
                  </label>
                  <input
                    type="text"
                    name="measurement"
                    value={formState.measurement}
                    onChange={handleChange}
                    placeholder="Enter measurement"
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
              </div>

              {/* Image Upload */}
              <div className="mt-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Images (Max 4)
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <div className="flex flex-wrap mt-2">
                  {formState.images.map((image, index) => (
                    <div key={index} className="relative mr-2 mb-2">
                      <img
                        src={URL.createObjectURL(image)}
                        alt="preview"
                        className="w-20 h-20 object-cover rounded"
                      />
                      <button
                        type="button"
                        onClick={() => removeImage(index)}
                        className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Step 2: Product Description */}
          {activeStep === 1 && (
            <div>
              <div>
                <label className="block text-gray-700 font-medium mb-2">
                  Product Description
                </label>
                <textarea
                  name="productDescription"
                  value={formState.productDescription}
                  onChange={handleChange}
                  placeholder="Enter product description"
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                />
              </div>
              {/* Instructions */}
              <div className="mt-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Does this product have usage instructions?
                </label>
                <select
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      hasInstructions: e.target.value === "yes",
                    })
                  }
                  className="w-full p-2 border border-gray-300 rounded mb-2"
                >
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>

                {formState.hasInstructions && (
                  <textarea
                    name="instructions"
                    value={formState.instructions}
                    onChange={handleChange}
                    placeholder="Enter usage instructions"
                    className="w-full p-2 border border-gray-300 rounded"
                    rows="4"
                  />
                )}
              </div>
            </div>
          )}

          <div className="mt-4 flex justify-between">
            {activeStep > 0 && (
              <button
                onClick={handleBack}
                className="bg-gray-500 text-white py-2 px-4 rounded"
              >
                Back
              </button>
            )}
            {activeStep === steps.length - 1 ? (
              <button
                onClick={onSubmit}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Submit
              </button>
            ) : (
              <button
                onClick={handleNext}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterProduct;
