import React from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { notification } from "antd"; 
import { CartContext } from "../../context/cart/CartProvider ";

export default function AddCart({ product, userId }) {
  const [quantity, setQuantity] = React.useState(1); 
  const { updateCart } = React.useContext(CartContext);

  const handleQuantityChange = (delta) => {
    setQuantity((prevQuantity) => Math.max(prevQuantity + delta, 1));
  };

  const handleAddToCart = async () => {
    if (quantity <= 0) {
      notification.error({
        message: "Invalid Quantity",
        description: "Please specify a valid quantity.",
      });
      return;
    }

    const cartItem = {
      productId: product.id,
      count: quantity,
      price: product.price,
    };

    try {
      await updateCart({ cartItems: [cartItem] });
      console.log("cartItem", cartItem);
      notification.success({
        message: "Success",
        description: "Cart updated successfully!",
      });
    } catch (error) {
      console.error("Error adding to cart:", error);
      notification.error({
        message: "Error",
        description: "Failed to add item to cart.",
      });
    }
  };

  return (
    <div className="flex flex-col items-start bg-white w-full">
      <p className="text-gray-600 mb-4">Select quantity</p>
      <div className="flex items-center gap-3 w-full">
        <div className="flex items-center gap-4 border border-gray-500 rounded-md px-4 py-2">
          <button
            onClick={() => handleQuantityChange(-1)}
            disabled={quantity <= 1}
            className="focus:outline-none disabled:opacity-50"
          >
            <AiOutlineMinus
              size={28}
              className="text-white bg-purple-600 border rounded-full p-1 shadow-md transition duration-150 ease-in-out transform hover:scale-110"
            />
          </button>
          <span className="text-lg font-medium">{quantity}</span>
          <button
            onClick={() => handleQuantityChange(1)}
            className="focus:outline-none"
          >
            <AiOutlinePlus
              size={28}
              className="text-white bg-purple-600 border rounded-full p-1 shadow-md transition duration-150 ease-in-out transform hover:scale-110"
            />
          </button>
        </div>

        <button
          onClick={handleAddToCart}
          className="bg-green-500 p-3 text-white font-semibold rounded-md shadow-md transition duration-200 hover:bg-green-600 w-full"
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
}
