import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaUsers, FaUserCheck, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import useSession from "../../../context/useSession";

const Customers = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [activeCustomers, setActiveCustomers] = useState(0);
  const { authToken } = useSession();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/customers`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const { customers, totalCustomers, activeCustomers } = response.data;
        setCustomers(customers);
        setTotalCustomers(totalCustomers);
        setActiveCustomers(activeCustomers);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    fetchCustomers();
  }, [authToken]);

  useEffect(() => {
    const searchCustomers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/customers/search`,
          {
            params: { query: searchTerm },
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setCustomers(response.data);
      } catch (error) {
        console.error("Error searching customers:", error);
      }
    };

    if (searchTerm) {
      searchCustomers();
    } else {
      // Optionally fetch all customers if search term is empty
      // fetchCustomers();
    }
  }, [searchTerm, authToken]);

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      {/* Analytics Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
        <div className="bg-white shadow rounded-lg p-4 flex items-center justify-around">
          <FaUsers className="text-4xl text-blue-600" />
          <div className="text-right">
            <p className="text-gray-700 text-xl font-bold">Total Users</p>
            <p className="text-3xl font-semibold text-blue-600">
              {totalCustomers}
            </p>
          </div>
        </div>
        <div className="bg-white shadow rounded-lg p-4 flex items-center justify-around">
          <FaUserCheck className="text-4xl text-green-500" />
          <div className="text-right">
            <p className="text-gray-700 text-xl font-bold">Active Customers</p>
            <p className="text-3xl font-semibold text-green-500">
              {activeCustomers}
            </p>
          </div>
        </div>
      </div>

      {/* Search Bar */}
      <div className="mb-4 flex items-center justify-start">
        <div className="relative w-full max-w-md">
          <input
            type="text"
            placeholder="Search customers..."
            className="w-full p-2 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-orange-600 outline-1"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
        </div>
      </div>

      {/* Customers Table */}
      <div className="bg-white shadow-lg rounded-lg overflow-x-auto">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Customer ID
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Name
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Email
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Status
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Total Purchases
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {customers.length > 0 ? (
              customers.map((customer) => (
                <tr key={customer.id}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {customer.id}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {customer.fullName}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {customer.email}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <span className="relative inline-flex items-center">
                      <FaUserCheck className="text-green-500 mr-2" />
                      <span className="font-semibold text-green-600">
                        Active
                      </span>
                    </span>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    KES {customer.totalPurchases}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <a
                      href={`/admin/customers/${customer.id}`}
                      className="bg-blue-600 text-white p-2 rounded-md"
                    >
                      View
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center"
                >
                  No customer found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Customers;
