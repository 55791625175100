import React, { useState, useRef, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import {
  FaSearch,
  FaShoppingCart,
  FaArrowRight,
  FaAngleRight,
  FaClinicMedical,
  FaUser,
  FaCaretDown,
} from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsSuitHeartFill } from "react-icons/bs";
import useSession from "../../../context/useSession";
import axios from "axios";
import { CartContext } from "../../../context/cart/CartProvider ";
import {
  Modal,
  Box,
  Button,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import { MdLocalShipping } from "react-icons/md";
import SubmitPrescriptionModal from "./Components/SubmitPrescriptionModal";

const HeaderBottom = () => {
  const [showCategory, setShowCategory] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [showCondition, setShowCondition] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [conditions, setConditions] = useState([]);
  const { cartItemCount, WishListItemCount } = useContext(CartContext);
  const { authToken } = useSession();
  const [products, setProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/get_products`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setProducts(response.data.products || []);
      } catch (error) {
        console.error("Error fetching products", error);
        setProducts([]);
      }
    };
    fetchProducts();
  }, [authToken]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoriesResponse = await fetchCategories();
      setCategories(categoriesResponse.data.data);

      const brandResponses = await fetchBrands();
      setBrands(brandResponses.data.data);

      const conditionResponses = await fetchCondition();
      setConditions(conditionResponses.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCategories = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/categories`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  // fetch brands
  const fetchBrands = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/colors`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  // fetch brands
  const fetchCondition = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/sizes`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const filtered = products.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    outline: "none",
    width: "90%",
    maxWidth: "600px",
    p: 4,
  };

  return (
    <div className="w-full bg-white relative border-b-[1px] border-b-gray-200">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
          <div className="flex flex-wrap items-center justify-between gap-10 pb-4 mdl:pb-2">
            {/* Shop by Category */}
            <div
              onClick={() => setShowCategory(!showCategory)}
              className="hidden md:flex h-14 cursor-pointer items-center gap-2 text-primeColor"
            >
              <motion.div
                className="text-[14px] font-normal flex gap-1 items-center cursor-pointer text-purple-900 bg-gray-100 mdl:bg-white p-2 rounded"
                whileHover={{ scale: 1.1, color: "#6b21a8" }}
                transition={{ duration: 0.3 }}
              >
                <p>Shop by Category</p>
                <FaAngleRight className="w-5 h-5" />
              </motion.div>

              {showCategory && (
                <motion.ul
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="absolute top-24 z-50 bg-white w-auto text-black h-auto p-4 pb-6"
                >
                  {categories.length > 0 ? (
                    categories.map((category) => (
                      <a
                        key={category.id}
                        href={`${window.location.origin}/category/${category.slug}`}
                      >
                        <motion.li
                          whileHover={{ scale: 1.05, color: "#6b21a8" }}
                          className="text-purple-900 font-medium px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-between gap-10"
                        >
                          {category.name}
                          <FaArrowRight className="w-4 h-4" />
                        </motion.li>
                      </a>
                    ))
                  ) : (
                    <li className="text-gray-400 px-4 py-1">
                      No categories available
                    </li>
                  )}
                </motion.ul>
              )}
            </div>

            {/* Shop by Brand */}
            <div
              onClick={() => setShowBrand(!showBrand)}
              className="hidden md:flex h-14 cursor-pointer items-center gap-2 text-primeColor"
            >
              <motion.div
                className="text-[14px] font-normal flex gap-1 items-center cursor-pointer text-purple-900"
                whileHover={{ scale: 1.1, color: "#6b21a8" }}
                transition={{ duration: 0.3 }}
              >
                <p>Shop by Brand</p>
                <FaAngleRight className="w-5 h-5" />
              </motion.div>

              {showBrand && (
                <motion.ul
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="absolute top-24 z-50 bg-white w-auto text-black h-auto p-4 pb-6"
                >
                  {brands.length > 0 ? (
                    brands.map((brand) => (
                      <a
                        key={brand.id}
                        href={`${window.location.origin}/brand/${brand.slug}`}
                      >
                        <motion.li
                          whileHover={{ scale: 1.05, color: "#6b21a8" }}
                          className="text-purple-900 font-medium px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-between gap-10"
                        >
                          {brand.name}
                          <FaArrowRight className="w-4 h-4" />
                        </motion.li>
                      </a>
                    ))
                  ) : (
                    <li className="text-gray-400 px-4 py-1">
                      No brands available
                    </li>
                  )}
                </motion.ul>
              )}
            </div>

            {/* Shop by Condition */}
            <div
              onClick={() => setShowCondition(!showCondition)}
              className="hidden md:flex h-14 cursor-pointer items-center gap-2 text-primeColor"
            >
              <motion.div
                className="text-[14px] font-normal flex gap-1 items-center cursor-pointer text-purple-900"
                whileHover={{ scale: 1.1, color: "#6b21a8" }}
                transition={{ duration: 0.3 }}
              >
                <p>Shop by Condition</p>
                <FaAngleRight className="w-5 h-5" />
              </motion.div>

              {showCondition && (
                <motion.ul
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="absolute top-24 z-50 bg-white w-auto text-black h-auto p-4 pb-6"
                >
                  {conditions.length > 0 ? (
                    conditions.map((condition) => (
                      <a
                        key={condition.id}
                        condition
                        href={`${window.location.origin}/condition/${condition.slug}`}
                      >
                        <motion.li
                          whileHover={{ scale: 1.05, color: "#6b21a8" }}
                          className="text-purple-900 font-medium px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-between gap-10"
                        >
                          {condition.name}
                          <FaArrowRight className="w-4 h-4" />
                        </motion.li>
                      </a>
                    ))
                  ) : (
                    <li className="text-gray-400 px-4 py-1">
                      No conditions available
                    </li>
                  )}
                </motion.ul>
              )}
            </div>

            {/* <motion.div
              className="text-[14px] font-normal hidden md:flex gap-1 items-center cursor-pointer text-purple-900"
              whileHover={{ scale: 1.1, color: "#6b21a8" }}
              transition={{ duration: 0.3 }}
            >
              <p>Offers</p>
            </motion.div> */}

            <motion.div
              onClick={() => setOpenModal2(true)}
              className="text-[14px] font-normal flex gap-2 items-center cursor-pointer text-purple-900 bg-gray-100 mdl:bg-white p-2 rounded"
              whileHover={{ scale: 1.1, color: "#6b21a8" }}
              transition={{ duration: 0.3 }}
            >
              <FaClinicMedical className="w-5 h-5" />
              <p>Submit Prescription</p>
            </motion.div>
          </div>

          <div className="relative w-full lg:w-[300px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl bg-gray-300">

            <div className="w-full bg-gray-300 flex items-center gap-4 p-2 rounded px-4"> 
              <button
                onClick={() => setOpenModal(true)}
                className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px] bg-gray-300"
              >
                <p>Search products</p>
              </button>
              <FaSearch className="w-5 h-5" />
            </div>

            <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              aria-labelledby="search-modal-title"
              aria-describedby="search-modal-description"
              closeAfterTransition
            >
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={modalVariants}
              >
                <Box sx={modalStyle}>
                  {/* Search bar inside modal */}
                  <div className="w-full flex items-center gap-2 my-4">
                    <InputBase
                      placeholder="Search your products here..."
                      value={searchQuery}
                      onChange={handleSearch}
                      fullWidth
                      sx={{
                        padding: "8px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    />
                    <IconButton>
                      <FaSearch />
                    </IconButton>
                  </div>

                  {/* Display search results */}
                  <div className="overflow-y-auto max-h-60">
                    {filteredProducts.length > 0 ? (
                      filteredProducts.map((item) => (
                        <a
                          href={`/product/${item.id}`}
                          key={item._id}
                          className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3 block"
                          onClick={() => {
                            setSearchQuery("");
                            setShowSearchBar(true);
                          }}
                        >
                          <img
                            className="w-24"
                            src={item?.images[0]?.imageUrl}
                            alt="productImg"
                          />
                          <div className="flex flex-col gap-1">
                            <p className="font-semibold text-lg">{item.name}</p>
                            <p className="text-xs">
                              {item.desc.length > 100
                                ? `${item.desc.slice(0, 100)}...`
                                : item.desc}
                            </p>
                            <p className="text-sm">
                              Price:{" "}
                              <span className="text-primeColor font-semibold">
                                KES {item.price}
                              </span>
                            </p>
                          </div>
                        </a>
                      ))
                    ) : (
                      <p>No products found.</p>
                    )}
                  </div>
                </Box>
              </motion.div>
            </Modal>
          </div>

          <div className="flex gap-7 mt-8 mb-4 lg:mb-0 lg:mt-0 items-center pr-6 cursor-pointer relative">
            <a href="/cart">
              <div className="relative">
                <FaShoppingCart className="text-purple-800 text-[24px]" />
                <span className="absolute font-titleFont -top-3 -right-2 text-xs w-5 h-5 flex items-center justify-center rounded-full bg-green-500 text-white">
                  {cartItemCount}
                </span>
              </div>
            </a>
            <a href="/wishlist">
              <div className="relative">
                <BsSuitHeartFill className="text-purple-800 text-[24px]" />
                <span className="absolute font-titleFont -top-3 -right-2 text-xs w-5 h-5 flex items-center justify-center rounded-full bg-purple-800 text-white">
                  {WishListItemCount}
                </span>
              </div>
            </a>

            <div onClick={() => setShowUser(!showUser)} className="flex">
              <FaUser className="text-purple-800 text-[24px]" />
              <FaCaretDown className="text-green-500 text-[24px]" />
            </div>
            {showUser && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-14 z-50 bg-white w-[400px] md:right-0 text-black h-auto p-4 pb-6"
              >
                <p className="text-purple-800 border-b border-b-1 border-black px-4 py-4 text-base font-medium">
                  My account
                </p>
                <motion.a
                  onClick={() => setShowUser(false)}
                  href="/admin/Sales"
                  whileHover={{ scale: 1.05, color: "#3b0764" }}
                  className="text-purple-900 font-medium px-4 py-4 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-between gap-10"
                >
                  <MdLocalShipping className="w-6 h-6" />
                  <div className="w-full">
                    <li className="text-base font-medium">My orders</li>
                    <p className="text-sm font-normal">
                      View and track online or pickup orders
                    </p>
                  </div>
                </motion.a>
                <motion.a
                  onClick={() => setShowUser(false)}
                  href="/admin/Sales"
                  whileHover={{ scale: 1.05, color: "#3b0764" }}
                  className="text-purple-900 font-medium px-4 py-4 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-between gap-10"
                >
                  <BsSuitHeartFill className="w-6 h-6 text-pink-700" />
                  <div className="w-full">
                    <li className="text-base font-medium">My Favourites</li>
                    <p className="text-sm font-normal">View saved products</p>
                  </div>
                </motion.a>

                <motion.a
                  onClick={() => setShowUser(false)}
                  href="/admin/Sales"
                  whileHover={{ scale: 1.05, color: "#3b0764" }}
                  className="text-purple-900 font-medium px-4 py-4 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-between gap-10"
                >
                  <FaUser className="w-6 h-6 text-black" />
                  <div className="w-full">
                    <li className="text-base font-medium">Account Settings</li>
                    <p className="text-sm font-normal">
                      Payment info, address, password
                    </p>
                  </div>
                </motion.a>
              </motion.ul>
            )}
          </div>
        </Flex>

        <SubmitPrescriptionModal
          open={openModal2}
          onClose={() => setOpenModal2(false)}
        />
      </div>
    </div>
  );
};

export default HeaderBottom;
