import React, { useState, useEffect } from "react";
import { Button, Form, Input, Table, Modal, notification, Spin } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import axios from "axios"; // Import axios

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const openNotification = (message) => {
    notification.open({
      message: message,
      duration: 2,
    });
  };

  const fetchPartners = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/others/partners`
      );
      setPartners(response.data.data);
    } catch (error) {
      console.error("Error fetching partners:", error);
    }
  };

  const handleRegisterPartner = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/others/partners`,
        values
      );
      setPartners([...partners, response.data]);
      openNotification("Partner registered successfully!");
      form.resetFields();
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error registering partner:", error);
      openNotification("Failed to register partner.");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setOrderId("");
  };

  const handleIssueOrder = () => {
    Modal.confirm({
      title: `Confirm Order`,
      content: `Are you sure you want to issue order ${orderId} to ${selectedPartner.name}?`,
      onOk: () => {
        openNotification(`Order ${orderId} issued to ${selectedPartner.name}`);
        handleCloseModal();
      },
    });
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <h1
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
      >
        Partner Management
      </h1>

      <Button
        type="primary"
        onClick={handleOpenModal}
        style={{ marginBottom: "20px" }}
      >
        Register Partner
      </Button>

      <h2 style={{ fontSize: "20px", marginTop: "40px", marginBottom: "20px" }}>
        Registered Partners
      </h2>
      <Table dataSource={partners} rowKey="mobile" pagination={{ pageSize: 5 }}>
        <Table.Column title="Name" dataIndex="name" />
        <Table.Column title="Mobile" dataIndex="mobile" />
        <Table.Column title="Email" dataIndex="email" />
        <Table.Column title="Location" dataIndex="location" />
        <Table.Column title="Operating Hours" dataIndex="operatingHours" />
        <Table.Column
          title="Actions"
          render={(text, partner) => (
            <div>
              <Button
                icon={<PhoneOutlined />}
                onClick={() => window.open(`tel:${partner.mobile}`)}
                style={{ marginRight: 8 }}
              />
            </div>
          )}
        />
      </Table>

      {/* Modal for registering a partner */}
      <Modal
        title="Register Partner"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null} // Set footer to null to customize it
      >
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" onFinish={handleRegisterPartner}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: "Please input the pharmacy name!" },
              ]}
            >
              <Input placeholder="Enter pharmacy name" />
            </Form.Item>
            <Form.Item
              name="mobile"
              label="Mobile"
              rules={[
                { required: true, message: "Please input the mobile number!" },
              ]}
            >
              <Input placeholder="Enter mobile number" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input a valid email!",
                },
              ]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item
              name="location"
              label="Location"
              rules={[
                { required: true, message: "Please input the location!" },
              ]}
            >
              <Input placeholder="Enter location" />
            </Form.Item>
            <Form.Item
              name="operatingHours"
              label="Operating Hours"
              rules={[
                {
                  required: true,
                  message: "Please input the operating hours!",
                },
              ]}
            >
              <Input placeholder="Enter operating hours" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Register Partner
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default Partners;
