import React from "react";
import { motion } from "framer-motion";
import image1 from "../../../../assets/landing/phamarcy.jpg"; // Example image for pharmacy
import image2 from "../../../../assets/landing/products.jpg"; // Example image for products
import image3 from "../../../../assets/landing/delivery.png"; // Example image for delivery
import ContactBottom from "./ContactBottom";

const AboutUs = () => {
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-10">
      {/* Header */}
      <motion.header
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
        className="text-center mb-10"
      >
        <h1 className="text-3xl font-bold text-purple-900">About Us</h1>
        <p className="text-lg text-gray-600 mt-2">
          Your trusted partner for health, wellness, and personal care.
        </p>
      </motion.header>

      {/* Mission, Vision, Values */}
      <motion.section
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
        className="grid grid-cols-1 md:grid-cols-3 gap-10 mb-10"
      >
        <div className="bg-white shadow-lg rounded-lg p-6 text-center transition-transform transform hover:scale-105">
          <h2 className="text-2xl font-semibold text-gray-800">Our Mission</h2>
          <p className="text-gray-600 mt-2">
            To provide high-quality medical and personal care products that
            enhance the well-being of our customers.
          </p>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 text-center transition-transform transform hover:scale-105">
          <h2 className="text-2xl font-semibold text-gray-800">Our Vision</h2>
          <p className="text-gray-600 mt-2">
            To be the leading pharmacy in the region, known for our exceptional
            service and wide range of products.
          </p>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 text-center transition-transform transform hover:scale-105">
          <h2 className="text-2xl font-semibold text-gray-800">Our Values</h2>
          <p className="text-gray-600 mt-2">
            Integrity, Quality, Innovation, and Customer Satisfaction are at the
            heart of everything we do.
          </p>
        </div>
      </motion.section>

      {/* Products Section */}
      <motion.section
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
          Our Products
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-white shadow-md rounded-lg p-6">
            <img
              src={image1}
              alt="Pharmacy"
              className="w-full h-48 object-cover rounded-md"
            />
            <h3 className="text-xl font-semibold text-gray-800 mt-4">
              Prescription Medications
            </h3>
            <p className="text-gray-600">
              We provide a variety of prescription medications to help manage
              your health effectively.
            </p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <img
              src={image2}
              alt="Personal Care Products"
              className="w-full h-48 object-cover rounded-md"
            />
            <h3 className="text-xl font-semibold text-gray-800 mt-4">
              Personal Care Products
            </h3>
            <p className="text-gray-600">
              Our selection includes skincare, haircare, and more to meet all
              your personal care needs.
            </p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <img
              src={image3}
              alt="Health Supplements"
              className="w-full h-48 object-contain rounded-md"
            />
            <h3 className="text-xl font-semibold text-gray-800 mt-4">
              Health Supplements
            </h3>
            <p className="text-gray-600">
              We offer high-quality vitamins and supplements to support your
              overall well-being.
            </p>
          </div>
        </div>
      </motion.section>

      {/* Delivery Structure Section */}
      <motion.section
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
        className="mb-10"
      >
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
          Our Delivery Structure
        </h2>
        <p className="text-lg text-gray-600 mb-4 text-center">
          We understand the importance of timely delivery, especially when it
          comes to medical and personal care products. At Aztech Pharmacy, we
          have developed a streamlined delivery structure to ensure that you
          receive your products safely and on time.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="bg-white shadow-lg rounded-lg p-6 text-center">
            <h3 className="text-xl font-semibold text-gray-800">
              Fast and Reliable Shipping
            </h3>
            <p className="text-gray-600 mt-2">
              Partnering with trusted courier services to provide efficient
              shipping options that meet your needs.
            </p>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6 text-center">
            <h3 className="text-xl font-semibold text-gray-800">
              Order Tracking
            </h3>
            <p className="text-gray-600 mt-2">
              Real-time tracking ensures peace of mind throughout the delivery
              process.
            </p>
          </div>
        </div>
      </motion.section>

      {/* Contact Us Section */}
      <motion.section
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
        className="text-center"
      >
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Get in Touch</h2>
        <p className="text-lg text-gray-600 mb-4">
          We would love to hear from you! Reach out to us with any questions or
          feedback.
        </p>
        <div>
          <ContactBottom />
        </div>
      </motion.section>
    </div>
  );
};

export default AboutUs;
