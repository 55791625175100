import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import Flex from "../../../components/designLayouts/Flex";
import useSession from "../../../context/useSession";

export const navBarListAdmin = [
  {
    _id: 1001,
    title: "Home",
    link: "/admin",
  },
  {
    _id: 1002,
    title: "Orders",
    link: "/admin/admin_orders",
  },
  {
    _id: 1003,
    title: "Users",
    link: "/admin/customers",
  },
  {
    _id: 1004,
    title: "Prescriptions",
    link: "/admin/prescriptions",
  },
  {
    _id: 1004,
    title: "Messages",
    link: "/admin/messages",
  },
  {
    _id: 1004,
    title: "Partners",
    link: "/admin/partners",
  },
];

const HeaderAdmin = () => {
  const { authToken, logout, user } = useSession();
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 667) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);

  return (
    <div className="w-full h-20 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200">
      <nav className="h-full px-4 max-w-container mx-auto relative">
        <Flex className="flex items-center justify-between h-full">
          <Link to="/admin">
            <div>
              <a
                href="#"
                className="font-extrabold font-serif text-2xl sm:text-3xl flex gap-2 text-purple-900"
              >
                {/* <img src={Logo} alt="Logo" className="w-10" /> */}
                AZTECH
              </a>
            </div>
          </Link>
          <div>
            {showMenu && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex items-center w-auto z-50 p-0 gap-2"
              >
                {navBarListAdmin.map(({ _id, title, link }) => (
                  <a
                    key={_id}
                    className="flex font-semibold hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-purple-900 hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                    href={link}
                    state={{ data: location.pathname.split("/")[1] }}
                  >
                    <li>{title}</li>
                  </a>
                ))}

                {authToken ? (
                  // Show Logout if the user is logged in
                  <a
                    className="flex font-semibold hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-purple-900 hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626]"
                    href="#"
                    onClick={logout}
                  >
                    <li>Logout</li>
                  </a>
                ) : (
                  // Show Login and Signup if the user is not logged in
                  <>
                    <a
                      className="flex font-semibold hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-purple-900 hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626]"
                      href="/admin_login"
                    >
                      <li>Login</li>
                    </a>
                    <a
                      className="flex font-semibold hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-purple-900 hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626]"
                      href="/admin_signup"
                    >
                      <li>Signup</li>
                    </a>
                  </>
                )}
              </motion.ul>
            )}
            <HiMenuAlt2
              onClick={() => setSidenav(!sidenav)}
              className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
            />
            {sidenav && (
              <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-[80%] h-full relative"
                >
                  <div className="w-full h-full bg-primeColor p-6">
                    <ul className="text-gray-200 flex flex-col gap-2">
                      {navBarListAdmin.map((item) => (
                        <li
                          className="font-semibold hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                          key={item._id}
                        >
                          <NavLink
                            to={item.link}
                            state={{ data: location.pathname.split("/")[1] }}
                            onClick={() => setSidenav(false)}
                          >
                            {item.title}
                          </NavLink>
                        </li>
                      ))}
                      {authToken ? (
                        <li
                          className="font-semibold hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                          onClick={() => {
                            logout();
                            setSidenav(false);
                          }}
                        >
                          Logout
                        </li>
                      ) : (
                        <>
                          <li
                            className="font-semibold hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                            onClick={() => setSidenav(false)}
                          >
                            <NavLink to="/admin_login">Login</NavLink>
                          </li>
                          <li
                            className="font-semibold hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                            onClick={() => setSidenav(false)}
                          >
                            <NavLink to="/admin_signup">Signup</NavLink>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                  <span
                    onClick={() => setSidenav(false)}
                    className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                  >
                    <MdClose />
                  </span>
                </motion.div>
              </div>
            )}
          </div>
        </Flex>
      </nav>
    </div>
  );
};

export default HeaderAdmin;
