import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import useSession from "../useSession";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartItemCount, setCartItemCount] = useState(0);

  const [wishListItems, setWishListItems] = useState([]);
  const [WishListItemCount, setWishListItemCount] = useState(0);

  const { authToken } = useSession();

  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/cart`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setCartItems(response.data.cartItems);
      setCartItemCount(response.data.totalItemCount);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const fetchWishListItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/wishlist`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setWishListItems(response.data.data);
      setWishListItemCount(response.data.totalItemCount);
    } catch (error) {
      console.error("Error fetching wishlist items:", error);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchCartItems();
      fetchWishListItems();
    }
  }, [authToken]);

  const clearCart = async () => {
    console.log("Clearing the cart...");
    try {
      await axios.delete(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/cart/clear`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Cart cleared successfully. Fetching updated cart items...");
      await fetchCartItems();
      setCartItemCount(0);
    } catch (error) {
      console.error("Error clearing cart:", error);
    }
  };

  // add to wishlist
  const addToWishlist = async (productId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/wishlist`,
        { productId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      fetchWishListItems();
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };

  const removeItemFromCart = async (itemId) => {
    console.log("Deleting item with ID:", itemId);
    try {
      await axios.delete(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/cart/delete/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Item deleted successfully. Fetching updated cart items...");
      await fetchCartItems();
      console.log("Cart items after deletion:", cartItems);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  // remove item from wishlist
  const removeItemFromWishList = async (itemId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/wishlist/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      await fetchWishListItems();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const updateCart = async (cartItemsToAdd) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/cart/add`,
        cartItemsToAdd,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      fetchCartItems(); // Refresh cart items and count
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const increaseQuantity = async (itemId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/cart/increase/${itemId}`,
        { count: 1 },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        fetchCartItems();
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error increasing quantity:", error);
      throw error; // Re-throw the error to be caught by handleIncrease
    }
  };

  const decreaseQuantity = async (itemId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/cart/decrease/${itemId}`,
        { count: 1 },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      fetchCartItems();
    } catch (error) {
      console.error("Error decreasing quantity:", error);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        removeItemFromCart,
        clearCart,
        increaseQuantity,
        decreaseQuantity,
        cartItemCount,
        updateCart,
        wishListItems,
        WishListItemCount,
        removeItemFromWishList,
        addToWishlist,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
