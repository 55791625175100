import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Modal,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const [response, setResponse] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/others/message`
        );
        setMessages(res.data.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, []);

  const handleOpen = (id) => {
    setCurrentMessageId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setResponse("");
    setCurrentMessageId(null);
  };

  const handleResponseSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/others/send_response/${currentMessageId}`,
        { response }
      );
      handleClose();
      setResponse("");
    } catch (error) {
      console.error("Error submitting response:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom align="center" color="primary">
        Messages
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((message) => (
                <TableRow key={message.id}>
                  <TableCell>{message.name}</TableCell>
                  <TableCell>{message.email}</TableCell>
                  <TableCell>{message.subject}</TableCell>
                  <TableCell>{message.message}</TableCell>
                  <TableCell>{message.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleOpen(message.id)}
                    >
                      Respond
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={messages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            padding: "20px",
            maxWidth: "400px",
            margin: "auto",
            marginTop: "100px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Respond to Message
          </Typography>
          <form onSubmit={handleResponseSubmit}>
            <TextField
              label="Response"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              required
              margin="normal"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              Send Response
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Messages;
