import React from 'react';

const HealthSupportBanner = () => {
  return (
    <div className="bg-purple-900 flex flex-col md:flex-row items-center justify-between p-6 rounded-lg shadow-md mb-16">
      <div className="text-left mb-4 md:mb-0">
        <h2 className="text-lg md:text-xl font-semibold text-white">
          We're Here to Support Your Health and Well-Being
        </h2>
        <p className="text-white mt-2 text-sm md:text-base">
          Helping you in your medical and personal welfare journey with our affordable and original products. Shop now to experience elegance.
        </p>
      </div>
      <div>
        <button className="bg-white/90 text-[#000000] py-2 px-4 rounded-lg hover:bg-white/80 transition duration-300 glow-button">
          Shop Now
        </button>
      </div>
    </div>
  );
};

export default HealthSupportBanner;
