import React from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import Products from "../../components/home/AllProducts/Products";
import Disclaimer from "../../components/Banner/Disclaimer";
import Offers from "../../components/home/Offers/Offers";
import HealthSupportBanner from "../../components/home/HealthSupportBanner";
import ContactBottom from "../../components/home/Header/Components/ContactBottom";

const Home = () => {
  return (
    <div className="w-full mx-auto bg-gray-100">
      <Banner />
      <Disclaimer />

      <div className="max-w-container mx-auto px-4">
        <Offers />
        <Sale />
        <NewArrivals />
        <HealthSupportBanner />
        <BestSellers />
        <ContactBottom />
        {/* <Products />
    
        <YearProduct /> */}
        {/* <SpecialOffers /> */}
      </div>
    </div>
  );
};

export default Home;
