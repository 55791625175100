import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { notification } from "antd";
import useSession from "../../context/useSession";
import {
  FaEnvelope,
  FaWhatsapp,
  FaQuestionCircle,
  FaPhone,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FeedbackPage = () => {
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { authToken } = useSession();

  const openNotification = (type, message) => {
    notification[type]({
      message: message,
      duration: 2,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/auth/feedback`,
        { feedback },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setFeedback("");
      setError(null);
      openNotification("success", "Feedback submitted successfully!");
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Failed to submit feedback.";
      setError(errorMessage);
      openNotification("error", errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full bg-gray-100 flex flex-col items-center justify-center">
      <motion.div
        className="flex flex-col md:flex-row items-start justify-center bg-gray-100 p-4 pt-24"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="md:w-1/2 md:pr-8">
          <h1 className="text-4xl font-bold mb-6 text-gray-800">
            We Value Your Feedback
          </h1>
          <p className="text-lg text-left mb-8">
            Your opinion matters to us! Here’s why we would love to hear from
            you:
          </p>
          <motion.ul
            className="mb-8 space-y-4"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <li className="flex items-center">
              <span className="mr-2 text-blue-600">✔️</span>
              Help us improve our products and services.
            </li>
            <li className="flex items-center">
              <span className="mr-2 text-blue-600">✔️</span>
              Share your experience with our Vintara bags.
            </li>
            <li className="flex items-center">
              <span className="mr-2 text-blue-600">✔️</span>
              Let us know what you love and what we can do better.
            </li>
            <li className="flex items-center">
              <span className="mr-2 text-blue-600">✔️</span>
              Be a part of our journey to provide the best healthcare and
              personal wellbeing products accross the +254.
            </li>
          </motion.ul>
        </div>

        <motion.form
          onSubmit={handleSubmit}
          className="w-full max-w-lg bg-white p-8 rounded-lg md:w-1/2 space-y-4"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <label className="block text-sm font-semibold text-gray-700">
            Your Feedback
          </label>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="5"
            placeholder="Share your thoughts..."
            required
          />
          <button
            type="submit"
            className={`w-full py-3 px-4 text-white rounded-md font-semibold ${
              loading
                ? "bg-gray-500 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-600"
            }`}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit Feedback"}
          </button>
          {error && <p className="mt-4 text-red-600 text-sm">{error}</p>}

          <motion.div
            className="mt-6 text-center w-full"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <p className="text-gray-600">
              Thank you for taking the time to share your thoughts with us!
            </p>
          </motion.div>
        </motion.form>
      </motion.div>

      <div className="pt-10 pb-2 w-3/4 flex flex-col md:flex-row items-center justify-around gap-8">
        {/* Email Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="mailto:support@example.com"
            className="flex items-center gap-4"
          >
            <FaEnvelope className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">
                Send us a message
              </span>
            </div>
          </a>
        </div>
        {/* WhatsApp Option */}
        <div className="bg-purple-900  backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="https://wa.me/1234567890"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-4"
          >
            <FaWhatsapp className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Chat with us</span>
            </div>
          </a>
        </div>
        {/* FAQ Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <Link to="/faq" className="flex items-center gap-4">
            <FaQuestionCircle className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Help & FAQ</span>
              <span className="text-white text-sm">Find answers quickly</span>
            </div>
          </Link>
        </div>
        {/* Call Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a href="tel:+1234567890" className="flex items-center gap-4">
            <FaPhone className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Contact us</span>
              <span className="text-white text-sm">+1 234 567 890</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FeedbackPage;
