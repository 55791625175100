import React, { useState } from "react";
import { BsCheckCircleFill, BsArrowRepeat } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { notification } from "antd";
import {
  FaEnvelope,
  FaWhatsapp,
  FaQuestionCircle,
  FaPhone,
} from "react-icons/fa";

const SignUp = () => {
  const [fullName, setfullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [errfullName, setErrfullName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errphoneNumber, setErrphoneNumber] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleName = (e) => {
    setfullName(e.target.value);
    setErrfullName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlephoneNumber = (e) => {
    setphoneNumber(e.target.value);
    setErrphoneNumber("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (
      fullName &&
      email &&
      EmailValidation(email) &&
      phoneNumber &&
      password &&
      password.length >= 6
    ) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/auth/user`,
          {
            fullName,
            email,
            phoneNumber,
            password,
          }
        );
        console.log("Response:", response); // Check the response

        if (response.status === 201) {
          notification.success({
            message: "Account created successfully",
            description: `Hello ${fullName}, Welcome to Vintara Africa. Enjoy your shopping adventure.`,
          });
          setfullName("");
          setEmail("");
          setphoneNumber("");
          setPassword("");
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error:", error); // Log the error
        const errorMessage =
          error.response?.data?.message ||
          "There was an error creating your account. Please try again later.";
        notification.error({
          message: "Sign Up Failed",
          description: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification.warning({
        message: "Form Incomplete",
        description: "Please fill in all fields correctly before submitting.",
      });
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-start pt-6 pb-6 bg-gray-100">
      <div className="w-full lgl:w-[500px] h-full flex flex-col justify-start">
        {successMsg ? (
          <div className="w-[500px]">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signin">
              <button className="w-full bg-gradient-to-r from-blue-700 to-blue-800 text-white py-4 rounded-lg hover:bg-gradient-to-l hover:from-blue-800 hover:to-blue-900 transition duration-300">
                Sign in
              </button>
            </Link>
          </div>
        ) : (
          <form className="w-full lgl:w-[450px] mt-2 mb-5 md:mt-0 md:mb-0 flex items-center justify-center">
            <div className="px-3 py-2 w-full h-[80%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
              <h1 className="font-titleFont font-semibold text-sm mdl:text-base mb-4">
                Create your account and proceed to experience the vintara
                elegance.
              </h1>
              <div className="flex flex-col gap-3">
                {/* client name */}
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handleName}
                    value={fullName}
                    className="w-full h-12 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400  outline-none"
                    style={{ outlineWidth: "0.5px" }}
                    type="text"
                    placeholder="Full name"
                  />
                  {errfullName && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errfullName}
                    </p>
                  )}
                </div>
                {/* Email */}
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-12 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400  outline-none"
                    type="email"
                    placeholder="Email address"
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>
                {/* phoneNumber Number */}
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handlephoneNumber}
                    value={phoneNumber}
                    className="w-full h-12 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400  outline-none"
                    type="text"
                    placeholder="phoneNumber number"
                  />
                  {errphoneNumber && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errphoneNumber}
                    </p>
                  )}
                </div>
                {/* Password */}
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handlePassword}
                    value={password}
                    className="w-full h-12 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400  outline-none"
                    type="password"
                    placeholder="Password"
                  />
                  {errPassword && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errPassword}
                    </p>
                  )}
                </div>

                <button
                  onClick={handleSignUp}
                  className={
                    "w-full bg-gradient-to-r from-green-500 to-green-500 text-white py-4 rounded-lg hover:bg-gradient-to-l hover:from-green-600 hover:to-green-600 transition-all duration-500 flex items-center justify-center"
                  }
                  disabled={loading}
                >
                  {loading ? (
                    <BsArrowRepeat className="animate-spin mr-2" />
                  ) : (
                    "Create Account"
                  )}
                </button>
                <p className="text-sm text-center font-titleFont font-medium">
                  Already have an Account?{" "}
                  <Link to="/admin_login">
                    <span className="hover:text-blue-600 duration-300">
                      Sign in
                    </span>
                  </Link>
                </p>
                <div className="flex items-center justify-between mt-10">
                  <p className="text-sm font-titleFont font-semibold text-purple-800 hover:text-white cursor-pointer duration-300">
                    © AZTECH Pharmacy
                  </p>
                  <p className="text-sm font-titleFont font-semibold text-purple-800 hover:text-white cursor-pointer duration-300">
                    Terms
                  </p>
                  <p className="text-sm font-titleFont font-semibold text-purple-800 hover:text-white cursor-pointer duration-300">
                    Privacy
                  </p>
                  <p className="text-sm font-titleFont font-semibold text-purple-800 hover:text-white cursor-pointer duration-300">
                    Security
                  </p>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="pt-10 pb-10 w-3/4 flex flex-col md:flex-row items-center justify-around gap-8">
        {/* Email Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="mailto:support@example.com"
            className="flex items-center gap-4"
          >
            <FaEnvelope className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">
                Send us a message
              </span>
            </div>
          </a>
        </div>
        {/* WhatsApp Option */}
        <div className="bg-purple-900  backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="https://wa.me/1234567890"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-4"
          >
            <FaWhatsapp className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Chat with us</span>
            </div>
          </a>
        </div>
        {/* FAQ Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <Link to="/faq" className="flex items-center gap-4">
            <FaQuestionCircle className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Help & FAQ</span>
              <span className="text-white text-sm">Find answers quickly</span>
            </div>
          </Link>
        </div>
        {/* Call Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a href="tel:+1234567890" className="flex items-center gap-4">
            <FaPhone className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Contact us</span>
              <span className="text-white text-sm">+1 234 567 890</span>
            </div>
          </a>
        </div>

      </div>
    </div>
  );
};

export default SignUp;
