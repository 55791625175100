import React from "react";
import Category from "./shopBy/Category";
import PriceFilter from "./shopBy/PriceFilter";
import ColorFilter from "./shopBy/ColorFilter";

const ShopSideNav = () => {
  return (
    <div className="w-full flex flex-col gap-6">
      <Category />
      {/* <ColorFilter /> */}
      <PriceFilter /> 
    </div>
  );
};

export default ShopSideNav;
