import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaUser,
  FaPhone,
  FaEnvelope,
  FaShoppingCart,
  FaStar,
  FaComment,
} from "react-icons/fa";
import moment from "moment";
import { useParams } from "react-router-dom";
import useSession from "../../../context/useSession";

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authToken } = useSession();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/customers/${id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setUser(response.data);
      } catch (err) {
        setError("Error fetching user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id, authToken]);

  if (loading)
    return <div className="p-6 text-center text-gray-600">Loading...</div>;
  if (error) return <div className="p-6 text-center text-red-600">{error}</div>;
  if (!user)
    return <div className="p-6 text-center text-gray-600">User not found</div>;

  const {
    fullName,
    email,
    phoneNumber,
    createdAt,
    cart,
    order,
    ratings,
    feedback,
  } = user;

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* User Info Section */}
      <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
        <div className="flex items-center mb-6">
          <FaUser className="text-5xl text-blue-600 mr-6" />
          <div>
            <h2 className="text-3xl font-semibold text-gray-900">{fullName}</h2>
            <p className="text-gray-700 flex items-center mb-2">
              <FaEnvelope className="text-gray-600 mr-2" />
              {email}
            </p>
            <p className="text-gray-700 flex items-center mb-2">
              <FaPhone className="text-gray-600 mr-2" />
              {phoneNumber}
            </p>
            <p className="text-gray-700 flex items-center">
              <span className="mr-2">Joined:</span>
              {moment(createdAt).format("MMMM Do YYYY")}
            </p>
          </div>
        </div>
      </div>

      {/* Cart Section */}
      <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
        <h3 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
          <FaShoppingCart className="text-orange-600 text-xl mr-3" />
          Cart Items
        </h3>
        <ul className="space-y-4">
          {cart.map((item) => (
            <li key={item.id} className="border-b border-gray-200 pb-4">
              <div className="flex justify-between text-gray-800">
                <span>Item ID: {item.id}</span>
                <span>Price: KES {item.price}</span>
              </div>
              <div className="flex justify-between text-gray-600 mt-2">
                <span>Size ID: {item.sizeId}</span>
                <span>Color ID: {item.colorId}</span>
                <span>Count: {item.count}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Orders Section */}
      <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
        <h3 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
          <FaShoppingCart className="text-green-600 text-xl mr-3" />
          Orders
        </h3>
        <ul className="space-y-4">
          {order.map((o) => (
            <li key={o.id} className="border-b border-gray-200 pb-4">
              <div className="flex justify-between text-gray-800">
                <span>Order ID: {o.id}</span>
                <span>Total Amount: KES {o.totalAmount}</span>
                <span>Date: {moment(o.createdAt).format("MMMM Do YYYY")}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Ratings Section */}
      <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
        <h3 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
          <FaStar className="text-yellow-600 text-xl mr-3" />
          Ratings
        </h3>
        <ul className="space-y-4">
          {ratings.map((rating) => (
            <li key={rating.id} className="border-b border-gray-200 pb-4">
              <div className="flex justify-between text-gray-800">
                <span>Rating: {rating.rate} Stars</span>
                <span>Product ID: {rating.productId}</span>
                <span>
                  Date: {moment(rating.createdAt).format("MMMM Do YYYY")}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Feedback Section */}
      <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
        <h3 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
          <FaComment className="text-teal-600 text-xl mr-3" />
          Feedback
        </h3>
        <ul className="space-y-4">
          {feedback.map((f) => (
            <li key={f.id} className="border-b border-gray-200 pb-4">
              <div className="flex flex-col text-gray-800">
                <p className="text-gray-700">Feedback: {f.feedback}</p>
                <span className="text-gray-600">
                  Date: {moment(f.createdAt).format("MMMM Do YYYY")}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserDetails;
