import React, { useState } from "react";
import axios from "axios";
import ContactBottom from "./Components/ContactBottom";
import { notification } from "antd"; 
import { FaSpinner } from "react-icons/fa"; 

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const openNotification = (type, message) => {
    notification[type]({
      message: message,
      placement: 'topRight', // You can change the placement
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/others/message`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        openNotification('success', 'Message sent successfully!');
        setFormData({
          name: "",
          email: "",
          mobile: "",
          subject: "",
          message: "",
        });
      } else {
        openNotification('error', 'Failed to send message. Please try again.');
      }
    } catch (error) {
      openNotification('error', 'An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center py-10">
      <h1 className="text-3xl font-bold text-purple-900 mb-6">
        We’d love to hear from you
      </h1>
      <p className="text-lg text-gray-700 mb-12 text-center">
        At Aztech Pharmacy, we are here to assist you with all your medical and
        personal care needs. Feel free to reach out to us!
      </p>

      {/* Contact Information Section */}
      <div className="flex flex-col md:flex-row justify-around w-full max-w-5xl mb-12 p-4">
        <div className="bg-green-100 shadow-md rounded-lg p-6 text-center flex-1 m-2 text-green-900">
          <h2 className="text-2xl font-semibold mb-4">Our Address</h2>
          <p className="text-gray-600">EcoBank towers, Muindi Mbingu street</p>
        </div>
        <div className="bg-green-100 shadow-md rounded-lg p-6 text-center flex-1 m-2 text-green-900">
          <h2 className="text-2xl font-semibold mb-4">Phone</h2>
          <p className="text-gray-600">+254 711 000 000</p>
        </div>
        <div className="bg-green-100 shadow-md rounded-lg p-6 text-center flex-1 m-2 text-green-900">
          <h2 className="text-2xl font-semibold mb-4">Email</h2>
          <p className="text-gray-600">support@aztechpharmacy.com</p>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl mb-12">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="border border-gray-300 rounded-lg p-2 w-full outline-none"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="border border-gray-300 rounded-lg p-2 w-full outline-none"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="mobile">
              Telephone Number
            </label>
            <input
              type="text"
              id="mobile"
              className="border border-gray-300 rounded-lg p-2 w-full outline-none"
              placeholder="Your Telephone Number"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="subject">
              Enquiry Subject
            </label>
            <input
              type="text"
              id="subject"
              className="border border-gray-300 rounded-lg p-2 w-full outline-none"
              placeholder="Enquiry Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              className="border border-gray-300 rounded-lg p-2 w-full outline-none"
              placeholder="Your Message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-green-500 text-white rounded-lg px-4 py-2 hover:bg-green-600 transition-all duration-300 font-medium flex items-center justify-center"
            disabled={isSubmitting}
          >
            {isSubmitting ? <FaSpinner className="animate-spin mr-2" /> : null}
            {isSubmitting ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>

      <div>
        <ContactBottom />
      </div>
    </div>
  );
};

export default ContactUs;
