import React, { useEffect, useState } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { useLocation } from "react-router-dom";

const Breadcrumbs = ({ prevLocation, title }) => {
  const location = useLocation();
  const [firstLocation, setFirstLocation] = useState("");
  const [secondLocation, setSecondLocation] = useState("");

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    setFirstLocation(pathSegments[1] || "");
    setSecondLocation(pathSegments[2] || "");
  }, [location]);

  return (
    <div className="w-full py-10 xl:py-10 flex flex-col gap-3">
      <h1 className="text-2xl text-purple-900 font-titleFont font-bold">
        {title}
      </h1>
      <p className="text-sm flex gap-1 font-normal capitalize flex items-center text-purple-900">
        <span className="text-sm font-semibold">
          {prevLocation === "" ? "Home" : prevLocation}
        </span>
        <span className="px-1">
          <HiOutlineChevronRight />
        </span>
        <span className="capitalize font-semibold text-primeColor">
          {firstLocation}
        </span>
        {secondLocation && (
          <>
            <span className="px-1">
              <HiOutlineChevronRight />
            </span>
            <span className="capitalize font-semibold text-primeColor">
              {secondLocation}
            </span>
          </>
        )}
      </p>
    </div>
  );
};

export default Breadcrumbs;
