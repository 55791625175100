import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import useSession from "./useSession";
import LoginModal from "../Admin/Components/Login/LoginModal";
import { FaSpinner } from "react-icons/fa";

const ProtectedRouteWrapper = ({ isUser }) => {
  const { authToken, user, loading } = useSession();
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  const handleLoginRedirect = () => {
    setShowModal(false);
    localStorage.setItem("redirectPath", location.pathname);
    window.location.href = "/signin";
  };

  const handleCancel = () => {
    setShowModal(false);
    window.location.href = "/";
  };

  useEffect(() => {
    if (!authToken && !loading) { 
      setShowModal(true);
    }
  }, [authToken, loading]);

  if (loading) {
    return (
      <div className="h-screen flex flex-col items-center justify-center gap-4">
        <FaSpinner className="animate-spin text-6xl text-blue-500" />
        <span className="ml-4 text-2xl text-red-500">Loading...</span>
      </div>
    );
  }

  if (!authToken) {
    return (
      <LoginModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        handleLoginRedirect={handleLoginRedirect}
        handleCancel={handleCancel}
      />
    );
  }

  if (user) {
    if (isUser && !user.isUser) {
      return <Navigate to="/" />;
    } else if (!isUser && user.isUser) {
      return <Navigate to="/" />;
    }
  }

  return <Outlet />;
};

export default ProtectedRouteWrapper;
