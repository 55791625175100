import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { motion } from "framer-motion";
import {
  FaEnvelope,
  FaWhatsapp,
  FaQuestionCircle,
  FaPhone,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What products do you offer?",
      answer:
        "We offer a variety of health and wellness products including prescription medicines, skincare items, over-the-counter medications, vitamins, supplements, and personal care products.",
    },
    {
      question: "How can I track my order?",
      answer:
        "Once your order is placed, you will receive a tracking number via email or SMS. Use this number to track your order in real-time on our website.",
    },
    {
      question: "Can I pick up my order instead of having it delivered?",
      answer:
        "Yes, we offer a 'Pick-Up' option. Simply select it during checkout and choose your nearest dispensing location. You'll be notified when your order is ready for collection.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept credit/debit cards, mobile money payments, online bank transfers, and cash on delivery in select locations.",
    },
    {
      question: "What is your return policy?",
      answer:
        "Returns are accepted for eligible products in case of incorrect, damaged, or defective items within 7 days of delivery. Items must be unused, in their original packaging, and accompanied by proof of purchase.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="max-w-5xl mx-auto my-8 p-4">
        <h2 className="text-2xl font-bold text-center mb-6">
          Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="border rounded-lg shadow-sm transition-all duration-300"
            >
              <div
                className="flex justify-between items-center p-4 cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-lg font-semibold">{faq.question}</h3>
                <span>
                  {activeIndex === index ? (
                    <AiOutlineMinus size={20} className="text-purple-600" />
                  ) : (
                    <AiOutlinePlus size={20} className="text-purple-600" />
                  )}
                </span>
              </div>
              {activeIndex === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.4, ease: "easeInOut" }} // Adjusted transition
                  className="p-4 text-gray-700 border-t bg-gray-50"
                >
                  {faq.answer}
                </motion.div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="pt-10 pb-10 w-3/4 flex flex-col md:flex-row items-center justify-around gap-8">
        {/* Email Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="mailto:support@example.com"
            className="flex items-center gap-4"
          >
            <FaEnvelope className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">
                Send us a message
              </span>
            </div>
          </a>
        </div>
        {/* WhatsApp Option */}
        <div className="bg-purple-900  backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="https://wa.me/1234567890"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-4"
          >
            <FaWhatsapp className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Chat with us</span>
            </div>
          </a>
        </div>
        {/* FAQ Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <Link to="/faq" className="flex items-center gap-4">
            <FaQuestionCircle className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Help & FAQ</span>
              <span className="text-white text-sm">Find answers quickly</span>
            </div>
          </Link>
        </div>
        {/* Call Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a href="tel:+1234567890" className="flex items-center gap-4">
            <FaPhone className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Contact us</span>
              <span className="text-white text-sm">+1 234 567 890</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
