import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Backdrop,
} from "@mui/material";

const LoginModal = ({
  open,
  handleClose,
  handleLoginRedirect,
  handleCancel,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        },
      }}
    >
      <DialogTitle>Login Required</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have no current active session.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          sx={{
            backgroundColor: "#f87171",
            color: "white",
            width: "100%",
            "&:hover": {
              backgroundColor: "#f87171",
            },
          }}
        >
          Close
        </Button>
        <Button
          onClick={handleLoginRedirect}
          sx={{
            backgroundColor: "#475569",
            color: "white",
            width: "100%",
            "&:hover": {
              backgroundColor: "#475569",
            },
          }}
        >
          Login/SignUp
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginModal;
