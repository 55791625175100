import React, { useState } from "react";
import { Modal, Box, Button } from "@mui/material";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { notification } from "antd";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";

const SubmitPrescriptionModal = ({ open, onClose }) => {
  const [prescriptionImage, setPrescriptionImage] = useState(null);
  const [location, setLocation] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.3)",
    borderRadius: 3,
    outline: "none",
    width: "90%",
    maxWidth: "600px",
    p: 4,
  };

  const handleSubmit = async () => {
    if (!prescriptionImage || !location || !contactNumber || !fullName) {
      notification.error({
        message: "Submission Error",
        description: "Please fill in all fields and upload a prescription.",
      });
      return;
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("prescriptionImage", prescriptionImage);
    formData.append("location", location);
    formData.append("contactNumber", contactNumber);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/others/Prescription`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      notification.success({
        message: "Submission Successful",
        description: "Your prescription has been submitted successfully.",
      });
      setIsSubmitting(false);
      onClose();
    } catch (error) {
      console.error("Error submitting prescription:", error);
      notification.error({
        message: "Submission Error",
        description: "An error occurred while submitting your prescription.",
      });
      setIsSubmitting(false);
    }
  };

  const handleImageChange = (event) => {
    setPrescriptionImage(event.target.files[0]);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="submit-prescription-modal-title"
      closeAfterTransition
    >
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={modalVariants}
      >
        <Box sx={modalStyle}>
          {/* Header Section with Image */}
          <div style={{ position: "relative", textAlign: "center" }}>
            <AiOutlineClose
              size={24}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              className="bg-purple-900 rounded-full text-white"
              onClick={onClose}
            />
          </div>

          {/* Form Section */}
          <div style={{ padding: "20px" }}>
            <h3 style={{ marginBottom: "15px", color: "#4A4A4A" }}>
              Submit Your Prescription
            </h3>
            <p
              style={{ marginBottom: "20px", fontSize: "14px", color: "#555" }}
            >
              Upload your prescription and provide your contact details for a
              quick and secure service.
            </p>
            <input
              type="file"
              onChange={handleImageChange}
              style={{
                display: "block",
                width: "100%",
                marginBottom: "15px",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            <input
              type="text"
              placeholder="Full Name" // New input for full name
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              style={{
                display: "block",
                width: "100%",
                marginBottom: "15px",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            <input
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              style={{
                display: "block",
                width: "100%",
                marginBottom: "15px",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            <input
              type="text"
              placeholder="Contact Number"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              style={{
                display: "block",
                width: "100%",
                marginBottom: "20px",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              style={{
                background: "#22c55e",
                color: "#fff",
                fontWeight: "bold",
                padding: "10px",
                borderRadius: "4px",
                marginBottom: "20px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <FaSpinner
                  className="animate-spin"
                  style={{ marginRight: "8px" }}
                />
              ) : null}
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </div>

          {/* Footer Disclaimer */}
          <div
            style={{
              textAlign: "center",
              padding: "15px",
              backgroundColor: "#f0f0f0",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
            }}
          >
            <p style={{ fontSize: "12px", color: "#777" }}>
              <strong>Your privacy is our priority!</strong> Your prescription
              data is kept safe and confidential. Submit your prescription with
              confidence and let us take care of the rest.
            </p>
          </div>
        </Box>
      </motion.div>
    </Modal>
  );
};

export default SubmitPrescriptionModal;
