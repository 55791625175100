import React from "react";
import Home from "./pages/Home/Home";

const AdminIndex = () => {
  return (
    <div className="mb-10 bg-[#F5F5F3]">
      <Home />
    </div>
  );
};

export default AdminIndex;
