import React from "react";
import {
  FaEnvelope,
  FaWhatsapp,
  FaQuestionCircle,
  FaPhone,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const ContactBottom = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="pt-10 pb-10 w-full flex flex-col md:flex-row items-center justify-around gap-8">
        {/* Email Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="mailto:support@example.com"
            className="flex items-center gap-4"
          >
            <FaEnvelope className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">
                Send us a message
              </span>
            </div>
          </a>
        </div>
        {/* WhatsApp Option */}
        <div className="bg-purple-900  backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a
            href="https://wa.me/1234567890"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-4"
          >
            <FaWhatsapp className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Chat with us</span>
            </div>
          </a>
        </div>
        {/* FAQ Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <Link to="/faq" className="flex items-center gap-4">
            <FaQuestionCircle className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Help & FAQ</span>
              <span className="text-white text-sm">Find answers quickly</span>
            </div>
          </Link>
        </div>
        {/* Call Option */}
        <div className="bg-purple-900 backdrop-blur-md shadow-2xl h-24 w-full md:w-1/5 flex items-center justify-center px-8 py-4 hover:bg-opacity-90 transition-all duration-300 rounded-xl border border-white border-opacity-30">
          <a href="tel:+1234567890" className="flex items-center gap-4">
            <FaPhone className="text-white text-4xl" />
            <div className="flex flex-col">
              <span className="text-white text-lg font-bold">Contact us</span>
              <span className="text-white text-sm">+1 234 567 890</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactBottom;
