import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaYoutube, FaLinkedin, FaGithub } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { paymentCard } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import axios from "axios";
import { notification } from "antd";

const Footer = () => {
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const emailValidation = () => {
    return String(emailInfo)
      .toLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubscription = async () => {
    if (emailInfo === "") {
      notification.error({
        message: "Error",
        description: "Please provide your email address!",
        placement: "topRight",
      });
      return;
    } else if (!emailValidation(emailInfo)) {
      notification.error({
        message: "Error",
        description: "Please provide a valid email address!",
        placement: "topRight",
      });
      return;
    }
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/customers/news`,
        {
          email: emailInfo,
        }
      );

      setSubscription(true);
      setEmailInfo("");
      notification.success({
        message: "Subscription Successful",
        description: "You've successfully subscribed to our newsletter!",
        placement: "topRight",
      });
    } catch (error) {
      console.error("Error subscribing:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Error subscribing. Please try again later.";
      notification.error({
        message: "Subscription Error",
        description: errorMessage,
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full bg-[#F0F4F8] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
          <FooterListTitle title="About AZTECH Pharmacy" />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%] text-gray-700">
              At AZTECH Pharmacy, we prioritize your health and well-being. Our
              commitment is to provide high-quality medical and personal care
              products tailored to your needs.
            </p>
            <ul className="flex items-center gap-2">
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-purple-900 text-white hover:text-gray-100 cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-purple-800 duration-300">
                  <FaYoutube />
                </li>
              </a>

              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-purple-900 text-white hover:text-gray-100 cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-purple-800 duration-300">
                  <FaFacebook />
                </li>
              </a>
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-purple-900 text-white hover:text-gray-100 cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-purple-800 duration-300">
                  <FaLinkedin />
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div>
          <FooterListTitle title="Products" />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              Health Supplements
            </li>
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              Personal Care
            </li>
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              Medical Equipment
            </li>
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              Skin Care
            </li>
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              New Arrivals
            </li>
          </ul>
        </div>
        <div>
          <FooterListTitle title="Customer Care" />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              Profile
            </li>
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              Orders
            </li>
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              Returns
            </li>
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              Payment Options
            </li>
            <li className="font-titleFont text-base text-gray-600 hover:text-purple-900 hover:underline cursor-pointer duration-300">
              Contact Us
            </li>
          </ul>
        </div>
        <div className="col-span-2 flex flex-col items-center w-full px-4">
          <FooterListTitle title="Subscribe to Our Newsletter" />
          <div className="w-full">
            <p className="text-center mb-4 text-gray-600">
              Enter your email to stay updated on our latest products and
              exclusive offers.
            </p>
            {subscription ? (
              <motion.p
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full text-center text-base font-titleFont font-semibold text-green-500"
              >
                Subscription Successful!
              </motion.p>
            ) : (
              <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
                <div className="flex flex-col w-full">
                  <input
                    onChange={(e) => setEmailInfo(e.target.value)}
                    value={emailInfo}
                    className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
                    type="text"
                    placeholder="Your email address...*"
                  />
                  {errMsg && (
                    <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
                      {errMsg}
                    </p>
                  )}
                </div>
                <button
                  onClick={handleSubscription}
                  disabled={loading}
                  className={`bg-pink-600 text-white rounded-md w-[50%] h-10 ${
                    loading ? "bg-gray-500" : "hover:bg-pink-500"
                  } duration-300 text-base tracking-wide`}
                >
                  {loading ? "Subscribing..." : "Subscribe"}
                </button>
              </div>
            )}

            <Image
              className={`w-[80%] lg:w-[60%] mx-auto ${
                subscription ? "mt-2" : "mt-6"
              }`}
              imgSrc={paymentCard}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
