import React, { useState } from 'react';
import { notification } from 'antd'; // Import Ant Design notification
import { motion } from 'framer-motion'; // Import Framer Motion

const JoinClub = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    dob: '',
    gender: '',
    phone: '',
    email: '',
    marketingConsent: false,
    termsAgreement: false,
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Show notification on successful submission
    notification.success({
      message: 'Membership Club',
      description: 'You have successfully joined the Aztech Pharmacy Membership Club!',
      placement: 'top',
    });

    // Clear the form (optional)
    setFormData({
      fullName: '',
      dob: '',
      gender: '',
      phone: '',
      email: '',
      marketingConsent: false,
      termsAgreement: false,
    });
  };

  return (
    <motion.div
      className="bg-gradient-to-r from-gray-100 to-gray-100 min-h-screen flex flex-col items-center justify-center py-12"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8 space-y-6">
        <h1 className="text-2xl font-bold text-center text-purple-900 mb-4">
          Join the Aztech Pharmacy Membership Club
        </h1>
        <p className="text-center text-gray-600 mb-6">
          Unlock a world of exclusive benefits tailored just for you!
        </p>

        <div className="benefits-section mb-8">
          <h2 className="text-xl font-semibold text-purple-900 mb-4">Why Become a Member?</h2>
          <motion.ul
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="list-disc list-inside text-gray-600 space-y-2"
          >
            <li>🚀 <strong>Exclusive Discounts:</strong> Get access to special pricing on your favorite products.</li>
            <li>📦 <strong>Discounted Deliveries:</strong> Enjoy reduced delivery fees for members.</li>
            <li>🛍️ <strong>Early Access:</strong> Be the first to know about new product launches and sales.</li>
            <li>📧 <strong>Personalized Offers:</strong> Receive tailored discounts and promotions directly to your inbox.</li>
            <li>💌 <strong>Health Insights:</strong> Get regular health and wellness tips from our experts.</li>
          </motion.ul>
        </div>

        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label className="block text-gray-700" htmlFor="fullName">Full Name:</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your full name"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700" htmlFor="dob">Date of Birth:</label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700" htmlFor="gender">Gender:</label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="">Select your gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-700" htmlFor="phone">Phone Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your phone number"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700" htmlFor="email">Email Address:</label>
            <input
              type="email"
              id="email" 
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="marketingConsent"
              name="marketingConsent"
              checked={formData.marketingConsent}
              onChange={handleChange}
              className="mr-2"
            />
            <label className="text-gray-700" htmlFor="marketingConsent">I consent to receiving marketing emails/SMS.</label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="termsAgreement"
              name="termsAgreement"
              checked={formData.termsAgreement}
              onChange={handleChange}
              className="mr-2"
              required
            />
            <label className="text-gray-700" htmlFor="termsAgreement">I agree to the terms & conditions.</label>
          </div>

          <motion.button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-200"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Join Now
          </motion.button>
        </form>
      </div>
    </motion.div>
  );
};

export default JoinClub;
