import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import axios from "axios";
import { useSelector } from "react-redux";
import useSession from "../../../context/useSession";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion"; 
import { FaSpinner } from "react-icons/fa"; 

const ShopByBrand = ({ itemsPerPage }) => {
  const { brandSlag } = useParams();
  const { authToken } = useSession();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);

  const selectedCategories = useSelector(
    (state) => state.orebiReducer.checkedCategorys
  );

  const minPrice = useSelector((state) => state.orebiReducer.minPrice);
  const maxPrice = useSelector((state) => state.orebiReducer.maxPrice);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true); 
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/brand/${brandSlag}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setProducts(response.data.products || []);
      } catch (error) {
        console.error("Error fetching products", error);
        setProducts([]);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchProducts();
  }, [authToken, brandSlag]);

  useEffect(() => {
    setItemOffset(0);
    setItemStart(1);
  }, [itemsPerPage]);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = products.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(products.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;
    const newStart = newOffset + 1;

    setItemOffset(newOffset);
    setItemStart(newStart);
  };

  // Filtering logic for products based on selected filters
  const filteredItems = currentItems.filter((item) => {
    const isCategorySelected =
      selectedCategories.length === 0 ||
      selectedCategories.some(
        (category) => category.name === item.category.name
      );

    const isPriceInRange = item.price >= minPrice && item.price <= maxPrice;

    return isCategorySelected && isPriceInRange;
  });

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }} 
      animate={{ opacity: 1, scale: 1 }} 
      transition={{ duration: 0.5 }} 
    >
      {isLoading ? (
        // Loading Spinner while fetching data
        <div className="flex justify-center items-center min-h-[50vh]">
          <FaSpinner className="text-4xl animate-spin text-gray-500" />
        </div>
      ) : filteredItems.length === 0 ? (
        // Show message when no products are available
        <p className="text-center text-xl text-gray-500">
          We currently don't have this product in our collection.
        </p> 
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
            {filteredItems.map((product) => (
              <div key={product.id} className="w-full">
                <Product
                  _id={product.id}
                  img={product.images?.[0]?.imageurl || ""}
                  productName={product.name || "Unknown Product"}
                  price={product.price}
                  badge={true}
                  des={product.desc || "No description available."}
                  averageRating={product.averageRating || 0}
                  ratingPercentages={product.ratingPercentages || []}
                  priceTracker={product.priceTracker}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
            <ReactPaginate
              nextLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel=""
              pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
              pageClassName="mr-6"
              containerClassName="flex text-base font-semibold font-titleFont py-10"
              activeClassName="bg-black text-white"
            />
            <p className="text-base font-normal text-lightText">
              Products from {itemStart} to{" "}
              {Math.min(endOffset, products.length)} of {products.length}
            </p>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default ShopByBrand;
