import React from "react";

const Heading = ({ heading }) => {
  return (
    <div className="text-center my-8">
      <h1 className="text-2xl font-bold text-[#000000] pb-2">{heading}</h1>
      <div className="relative flex justify-center items-center mt-2">
        <span className="block w-20 h-1 bg-pink-800 opacity-50"></span>
        <span className="w-10 mx-2 flex justify-center">
          <span className="block w-1 h-1 rounded-full bg-pink-800"></span>
          <span className="block w-4 h-1 bg-pink-800 mx-1"></span>
          <span className="block w-1 h-1 rounded-full bg-pink-800"></span>
        </span>
        <span className="block w-20 h-1 bg-pink-800 opacity-50"></span>
      </div>
    </div>
  );
};

export default Heading;
