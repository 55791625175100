import React from "react";
import AllProducts from "../../pages/Products/AllProducts";

const AdminDash = () => {
  return (
    <div>
      <AllProducts />
    </div>
  );
};

export default AdminDash;
