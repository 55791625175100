import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { notification } from "antd";

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("authToken") || null
  );
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkTokenExpiration = () => {
    if (authToken) {
      try {
        const { exp } = jwtDecode(authToken);
        if (exp < Date.now() / 1000) {
          logout();
          redirectToLogin();
        } else {
          fetchUser();
        }
      } catch (error) {
        console.error("Failed to decode token:", error);
        logout();
        redirectToLogin();
      }
    } else {
      setLoading(false);
    }
  };

  const fetchUser = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/auth/me`,
        {
          headers: { Authorization: `Bearer ${token || authToken}` },
        }
      );
      const userData = response.data;
      setUser(userData);
      return userData;
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setUser(null);
      logout();
      redirectToLogin();
    } finally {
      setLoading(false);
    }
  };

  const redirectToLogin = () => {
    if (user?.isUser === false) {
      window.location.href = "/admin_login";
    } else {
      window.location.href = "/signin";
    }
  };
  useEffect(() => {
    checkTokenExpiration(); // Check token expiration on load
  }, [authToken]);

  // Login function
  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/auth/admin_login`,
        {
          email,
          password,
        }
      );
      const { token } = response.data;
      localStorage.setItem("authToken", token);
      setAuthToken(token);
      notification.success({
        message: "Login Successful",
        description: `Welcome back, ${email}! You have successfully logged in.`,
      });
      const user = await fetchUser(token);
      return user;
    } catch (error) {
      console.error("Login failed:", error);
      notification.error({
        message: "Login Failed",
        description:
          error.response?.data?.message ||
          "Invalid email or password. Please try again.",
      });

      return false;
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem("authToken");
    setAuthToken(null);
    setUser(null);
  };

  const value = {
    authToken,
    user,
    loading,
    login,
    logout,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};
