import { useContext, useState } from "react";
import { SessionContext } from "./SessionContext";

const useSession = () => {
  const context = useContext(SessionContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const login = async (email, password) => {
    setErrorMsg("");
    setSuccessMsg("");

    const success = await context.login(email, password);
    if (success) {
      setSuccessMsg("Login successful!");
      const redirectPath = localStorage.getItem("redirectPath") || "/";
      window.location.href = redirectPath;
    } else {
      setErrorMsg("Invalid email or password");
      window.location.href = "/";
    }

    return success;
  };

  return { ...context, login, errorMsg, successMsg };
};

export default useSession;
