import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import useSession from "../../../context/useSession";
import axios from "axios";
import SampleNextArrow from "../NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../NewArrivals/SamplePrevArrow";
import Slider from "react-slick";
import BestProduct from "./BestProduct";

const BestSellers = () => {
  const { authToken } = useSession();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/get_products`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setProducts(response.data.products || []);
      } catch (error) {
        console.error("Error fetching products", error);
        setProducts([]);
      }
    };

    fetchProducts();
  }, [authToken]);

  const getPriceRange = (sizes) => {
    if (!sizes || sizes.length === 0) return "N/A";

    const prices = sizes.map((size) => size.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    return minPrice === maxPrice ? `${minPrice}` : `${minPrice} - ${maxPrice}`;
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="w-full pb-10">
      <Heading heading="Most Popular Products" />
      <div>
        <Slider {...settings}>
          {products.map((product) => (
            <div key={product.id} className="px-2">
              <BestProduct
                _id={product.id}
                img={product.images?.[0]?.imageUrl || ""}
                productName={product.name || "Unknown Product"}
                price={product.price}
                badge={true}
                des={product.desc || "No description available."}
                averageRating={product.averageRating || 0}
                ratingPercentages={product.ratingPercentages || []}
                priceTracker={product?.priceTracker}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BestSellers;
