import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import Logo from "../../../assets/LOGO/logo.png";
import Flex from "../../designLayouts/Flex";
import Category from "../../pageProps/shopPage/shopBy/Category";
import ColorFilter from "../../pageProps/shopPage/shopBy/ColorFilter";
import PriceFilter from "../../pageProps/shopPage/shopBy/PriceFilter";
import useSession from "../../../context/useSession";
import axios from "axios";
import {
  FaSearch,
  FaShoppingCart,
  FaArrowRight,
  FaAngleRight,
  FaClinicMedical,
  FaUser,
  FaCaretDown,
} from "react-icons/fa";

const Header = () => {
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const [openSection, setOpenSection] = useState(null);
  const location = useLocation();
  const { authToken, logout, user } = useSession();
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [conditions, setConditions] = useState([]);

  const [showCondition, setShowCondition] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [showBrand, setShowBrand] = useState(false);

  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 667) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);

  const handleToggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoriesResponse = await fetchCategories();
      setCategories(categoriesResponse.data.data);

      const brandResponses = await fetchBrands();
      setBrands(brandResponses.data.data);

      const conditionResponses = await fetchCondition();
      setConditions(conditionResponses.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCategories = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/categories`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  const fetchBrands = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/colors`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  const fetchCondition = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/sizes`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  return (
    <div className="w-full h-20 bg-white sticky top-0 z-50">
      <nav className="h-full px-4 max-w-container mx-auto relative">
        <Flex className="flex items-center justify-between h-full">
          <Link to="/">
            <div>
              <a
                href="#"
                className="font-extrabold font-serif text-2xl sm:text-3xl flex gap-2 text-purple-900"
              >
                {/* <img src={Logo} alt="Logo" className="w-10" /> */}
                AZTECH
              </a>
            </div>
          </Link>
          <div>
            {showMenu && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex items-center w-auto z-50 p-0 gap-2"
                state={{ data: location.pathname.split("/")[1] }}
              >
                <li className="flex font-semibold hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-purple-700 hover:underline underline-offset-[4px] decoration-[1px] hover:text-purple-800 md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0">
                  <a href="/shop">Shop</a>
                </li>

                {authToken && user === false && (
                  <li className="flex font-semibold hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-purple-700 hover:underline underline-offset-[4px] decoration-[1px] hover:text-purple-800 md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0">
                    <a href="/shop">Admin</a>
                  </li>
                )}

                {authToken ? (
                  <a
                    className="flex font-semibold hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-purple-700 hover:underline underline-offset-[4px] decoration-[1px] hover:text-purple-800 md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                    href="/"
                    onClick={logout}
                  >
                    <li>Logout</li>
                  </a>
                ) : (
                  <>
                    <a
                      className="flex font-semibold hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-purple-700 hover:underline underline-offset-[4px] decoration-[1px] hover:text-purple-800 md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                      href="/signin"
                    >
                      <li>Login</li>
                    </a>
                    <a
                      className="flex font-semibold hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-purple-700 hover:underline underline-offset-[4px] decoration-[1px] hover:text-purple-800 md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                      href="/signup"
                    >
                      <li>Signup</li>
                    </a>
                  </>
                )}
              </motion.ul>
            )}
            <HiMenuAlt2
              onClick={() => setSidenav(!sidenav)}
              className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
            />
            {sidenav && (
              <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-[80%] h-full relative"
                >
                  <div className="w-full h-full bg-purple-950 p-6">
                    <div className="flex justify-center items-center mt-4 mb-6">
                      {/* <img src={Logo} alt="Company Logo" className="w-32" /> */}
                      <Link to="/">
                        <div>
                          <a
                            href="#"
                            className="font-extrabold font-serif text-2xl sm:text-3xl flex gap-2 text-white"
                          >
                            AZTECH
                          </a>
                        </div>
                      </Link>
                    </div>

                    <div className="flex flex-col justify-center mt-8 overflow-y-auto max-h-[70vh] py-8">
                      <div className="mt-4">
                        {/* Shop by Category */}
                        <div
                          onClick={() => setShowCategory(!showCategory)}
                          className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
                        >
                          <motion.div
                            className="w-full text-[14px] font-normal flex items-center justify-between cursor-pointer text-white"
                            whileHover={{ scale: 1.1, color: "#6b21a8" }}
                            transition={{ duration: 0.3 }}
                          >
                            <p className="font-titleFont text-lg">
                              Shop by Category
                            </p>
                            <FaAngleRight className="w-5 h-5" />
                          </motion.div>

                          {showCategory && (
                            <motion.ul
                              initial={{ y: 30, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ duration: 0.5 }}
                              className="absolute top-24 z-50 bg-white w-auto text-black h-auto p-4 pb-6"
                            >
                              {categories.length > 0 ? (
                                categories.map((category) => (
                                  <a
                                    key={category.id}
                                    href={`${window.location.origin}/category/${category.slug}`}
                                  >
                                    <motion.li
                                      whileHover={{
                                        scale: 1.05,
                                        color: "#6b21a8",
                                      }}
                                      className="text-purple-900 font-medium px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-between gap-10"
                                    >
                                      {category.name}
                                      <FaArrowRight className="w-4 h-4" />
                                    </motion.li>
                                  </a>
                                ))
                              ) : (
                                <li className="text-gray-400 px-4 py-1">
                                  No categories available
                                </li>
                              )}
                            </motion.ul>
                          )}
                        </div>
                      </div>

                      <div className="mt-4">
                        {/* Shop by Condition */}
                        <div
                          onClick={() => setShowCondition(!showCondition)}
                          className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
                        >
                          <motion.div
                            className="w-full text-[14px] flex items-center justify-between cursor-pointer text-white"
                            whileHover={{ scale: 1.1, color: "#6b21a8" }}
                            transition={{ duration: 0.3 }}
                          >
                            <p className="font-titleFont text-lg">
                              Shop by Condition
                            </p>
                            <FaAngleRight className="w-5 h-5" />
                          </motion.div>

                          {showCondition && (
                            <motion.ul
                              initial={{ y: 30, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ duration: 0.5 }}
                              className="absolute top-24 z-50 bg-white w-auto text-black h-auto p-4 pb-6"
                            >
                              {conditions.length > 0 ? (
                                conditions.map((condition) => (
                                  <a
                                    key={condition.id}
                                    condition
                                    href={`${window.location.origin}/condition/${condition.slug}`}
                                  >
                                    <motion.li
                                      whileHover={{
                                        scale: 1.05,
                                        color: "#6b21a8",
                                      }}
                                      className="text-purple-900 font-medium px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-between gap-10"
                                    >
                                      {condition.name}
                                      <FaArrowRight className="w-4 h-4" />
                                    </motion.li>
                                  </a>
                                ))
                              ) : (
                                <li className="text-gray-400 px-4 py-1">
                                  No conditions available
                                </li>
                              )}
                            </motion.ul>
                          )}
                        </div>
                      </div>

                      <div className="mt-4">
                        {/* Shop by Brand */}
                        <div
                          onClick={() => setShowBrand(!showBrand)}
                          className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
                        >
                          <motion.div
                            className="w-full text-[14px] font-normal flex items-center justify-between cursor-pointer text-white"
                            whileHover={{ scale: 1.1, color: "#6b21a8" }}
                            transition={{ duration: 0.3 }}
                          >
                            <p className="font-titleFont text-lg">
                              Shop by Brand
                            </p>
                            <FaAngleRight className="w-5 h-5" />
                          </motion.div>

                          {showBrand && (
                            <motion.ul
                              initial={{ y: 30, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ duration: 0.5 }}
                              className="absolute top-24 z-50 bg-white w-auto text-black h-auto p-4 pb-6"
                            >
                              {brands.length > 0 ? (
                                brands.map((brand) => (
                                  <a
                                    key={brand.id}
                                    href={`${window.location.origin}/brand/${brand.slug}`}
                                  >
                                    <motion.li
                                      whileHover={{
                                        scale: 1.05,
                                        color: "#6b21a8",
                                      }}
                                      className="text-purple-900 font-medium px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-between gap-10"
                                    >
                                      {brand.name}
                                      <FaArrowRight className="w-4 h-4" />
                                    </motion.li>
                                  </a>
                                ))
                              ) : (
                                <li className="text-gray-400 px-4 py-1">
                                  No brands available
                                </li>
                              )}
                            </motion.ul>
                          )}
                        </div>
                      </div>

                      <div className="mt-4 flex items-center justify-between w-full">
                        <a
                          href="/about-us"
                          className="text-white no-underline font-titleFont text-lg"
                        >
                          About us
                        </a>
                        <FaAngleRight className="w-5 h-5" />
                      </div>

                      <div className="mt-4 flex items-center justify-between w-full">
                        <a
                          href="/contact"
                          className="text-white no-underline font-titleFont text-lg"
                        >
                          Contact us
                        </a>
                        <FaAngleRight className="w-5 h-5" />
                      </div>

                      <div className="mt-4 flex items-center justify-between w-full">
                        <a
                          href="/faqs"
                          className="text-white no-underline font-titleFont text-lg"
                        >
                          FAQs
                        </a>
                        <FaAngleRight className="w-5 h-5" />
                      </div>
                    </div>

                    <div className="absolute bottom-10 w-[80%] flex justify-center gap-2 items-center">
                      {authToken ? (
                        <a
                          className="w-full text-center text-white bg-green-500 p-2 rounded-lg font-titleFont text-lg"
                          href="/"
                          onClick={logout}
                        >
                          Logout
                        </a>
                      ) : (
                        <>
                          <a
                            className="w-full text-center text-white bg-green-500 p-2 rounded-lg font-titleFont text-lg"
                            href="/signin"
                          >
                            Login
                          </a>
                          <a
                            className="w-full text-center text-white bg-pink-600 p-2 rounded-lg font-titleFont text-lg"
                            href="/signup"
                          >
                            Signup
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                  <span
                    onClick={() => setSidenav(false)}
                    className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                  >
                    <MdClose />
                  </span>
                </motion.div>
              </div>
            )}
          </div>
        </Flex>
      </nav>
    </div>
  );
};

export default Header;
