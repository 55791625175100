import React from 'react'
import ProductsAdmin from '../../Components/ProductView/ProductsAdmin'

const AllProducts = () => {
  return (
    <div>
      <ProductsAdmin />
    </div>
  )
}

export default AllProducts
