import React, { useState } from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import Image from "../../designLayouts/Image";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Badge from "../Products/Badge";
import { AiOutlineLineChart } from "react-icons/ai";
import { CartContext } from "../../../context/cart/CartProvider ";
import { notification } from "antd";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "600px",
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[10],
  padding: theme.spacing(4),
  borderRadius: "12px",
  overflow: "hidden",
  zIndex: 1000,
}));

const BestProduct = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishList, setWishList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [quantity, setQuantity] = React.useState(1);
  const { updateCart } = React.useContext(CartContext);

  const {
    _id,
    productName,
    img,
    badge,
    price,
    averageRating,
    ratingPercentages = [],
    priceTracker,
  } = props;

  const handleAddToCart = async () => {
    if (quantity <= 0) {
      notification.error({
        message: "Invalid Quantity",
        description: "Please specify a valid quantity.",
      });
      return;
    }

    const cartItem = {
      productId: _id,
      count: quantity,
      price: price,
    };

    try {
      await updateCart({ cartItems: [cartItem] });
      console.log("cartItem", cartItem);
      notification.success({
        message: "Success",
        description: "Cart updated successfully!",
      });
    } catch (error) {
      console.error("Error adding to cart:", error);
      notification.error({
        message: "Error",
        description: "Failed to add item to cart.",
      });
    }
  };

  const hasDiscount = priceTracker && priceTracker.status === "decrease";

  const idString = (_id) => String(_id).toLowerCase().split(" ").join("");
  const rootId = idString(_id);

  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: props,
      },
    });
  };

  const handleWishList = () => {
    toast.success("Product added to wishlist");
    setWishList([...wishList, props]);
  };

  const isInWishlist = (id) => wishList.some((item) => item._id === id);

  const getRatingPercentages = () => {
    const colors = ["#bbf7d0", "#86efac", "#4ade80", "#22c55e", "#15803d"];

    return ratingPercentages.map((rate, index) => (
      <div key={index} className="flex items-center mb-1">
        <span className="text-sm font-semibold w-16">{index + 1} Star</span>
        <div
          className="relative flex-grow bg-gray-300 rounded-full h-3 mx-2"
          style={{ backgroundColor: colors[index] }}
        >
          <div
            style={{
              width: `${rate.percentage}%`,
              backgroundColor: colors[index],
            }}
            className="absolute top-0 left-0 h-full rounded-full"
          />
        </div>
        <span className="text-sm font-semibold w-16 text-right">
          {rate.percentage.toFixed(2)}%
        </span>
      </div>
    ));
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= rating ? (
          <AiFillStar key={i} className="text-pink-600" />
        ) : (
          <AiOutlineStar key={i} className="text-pink-600" />
        )
      );
    }
    return stars;
  };

  return (
    <div className="relative group shadow-lg rounded-lg overflow-hidden mb-6 bg-white h-[470px]">
      <div className="relative overflow-hidden h-72 bg-blue-600">
        <div onClick={handleProductDetails} className="cursor-pointer">
          <Image
            className="w-full h-full object-cover group-hover:scale-110 duration-1000 ease-in-out transition-all"
            imgSrc={img}
          />
        </div>
        <div className="absolute top-0 left-0">
          {badge && (
            <Badge
              text={
                <span className="flex items-center gap-1 text-white text-xs font-normal">
                  Top <AiOutlineLineChart />
                </span>
              }
            />
          )}
        </div>
        <div className="absolute top-2 right-2">
          <button onClick={handleWishList}>
            {isInWishlist(_id) ? (
              <BsSuitHeartFill className="text-pink-600 hover:text-pink-800 duration-300 ease-in-out trasition-all h-6 w-6" />
            ) : (
              <BsSuitHeartFill className="text-gray-300 duration-300 ease-in-out trasition-all h-6 w-6" />
            )}
          </button>
        </div>
      </div>
      <div className="p-4 flex flex-col justify-between gap-2">
        <h2 className="text-base font-semibold text-black pt-4">
          {productName}
        </h2>
        <div className="flex items-center justify-between pt-4">
          <p className="text-[#000000] text-sm">KES {price}</p>
          <span
            onClick={() => setOpenModal(true)}
            className="flex text-primeColor items-end justify-end cursor-pointer"
          >
            {renderStars(averageRating)}
          </span>
        </div>

        {/* {hasDiscount && (
          <div className="mt-2 flex items-center gap-2 text-orange-600 font-medium bg-orange-100 p-1 rounded">
            <FaTag className="text-orange-500" />
            <span>On offer</span>
          </div>
        )} */}
        <button
          onClick={handleAddToCart}
          className="py-2 px-2 bg-gray-100 text-[#000000] text-sm font-normal hover:bg-gray-200 transition-colors absolute bottom-0 left-0 flex items-center gap-2"
        >
          <FaShoppingCart className="inline ml-2 text-purple-900" /> Add to Cart
        </button>
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="product-details-modal"
        aria-describedby="modal-description"
      >
        <StyledBox>
          <div className="flex items-center mb-4">
            <span className="cursor-pointer text-primeColor flex items-center">
              {renderStars(averageRating)}{" "}
              <div className="pl-4 font-bold">{averageRating} out of 5</div>
            </span>
          </div>

          <div className="flex flex-col gap-2 mb-4">
            {getRatingPercentages()}
          </div>

          <a href={`/product/${rootId}`}>
            <button className="w-full py-2 bg-purple-800 text-white rounded-lg font-semibold hover:bg-orange-600 transition-colors">
              View More
            </button>
          </a>
        </StyledBox>
      </Modal>
    </div>
  );
};

export default BestProduct;
