import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMaxPrice, setMinPrice } from "../../../../redux/orebiSlice";
import NavTitle from "./NavTitle";
import { IoPricetag } from "react-icons/io5"; // Using react-icons for a modern look

const PriceFilter = () => {
  const dispatch = useDispatch();
  const minPrice = useSelector((state) => state.orebiReducer.minPrice);
  const maxPrice = useSelector((state) => state.orebiReducer.maxPrice);

  const handleMinPriceChange = (e) => {
    dispatch(setMinPrice(parseFloat(e.target.value) || 0));
  };

  const handleMaxPriceChange = (e) => {
    dispatch(setMaxPrice(parseFloat(e.target.value) || 10000));
  };

  const minRange = 0;
  const maxRange = 10000;

  const progressWidth =
    maxPrice > minPrice
      ? ((maxPrice - minPrice) / (maxRange - minRange)) * 100
      : 0;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 relative mb-8">
      {/* Title */}
      <NavTitle title="Shop by Price" icons={true} />

      {/* Price Inputs */}
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4 text-gray-500">
          <label
            htmlFor="minPrice"
            className="text-xs font-medium flex items-center"
          >
            <IoPricetag className="mr-2 text-blue-600" /> Min Price
          </label>
          <label
            htmlFor="maxPrice"
            className="text-xs font-medium flex items-center"
          >
            <IoPricetag className="mr-2 text-green-600" /> Max Price
          </label>
        </div>

        <div className="flex items-center gap-4 mb-4">
          <input
            type="number"
            id="minPrice"
            value={minPrice || ""}
            onChange={handleMinPriceChange}
            min={minRange}
            className="w-full max-w-xs p-2 border border-gray-300 rounded-lg shadow-md outline-none focus:ring-2 focus:ring-blue-500 text-xs lg:text-sm transition-all duration-300"
            placeholder="Min Price"
          />
          <input
            type="number"
            id="maxPrice"
            value={maxPrice || ""}
            onChange={handleMaxPriceChange}
            min={minRange}
            className="w-full max-w-xs p-2 border border-gray-300 rounded-lg shadow-md outline-none focus:ring-2 focus:ring-green-500 text-xs lg:text-sm transition-all duration-300"
            placeholder="Max Price"
          />
        </div>

        {/* Price Range Bar */}
        <div className="relative w-full h-8 bg-gray-100 rounded-full overflow-hidden shadow-inner">
          <div
            className="absolute top-0 left-0 h-full bg-gradient-to-r from-blue-400 to-green-400 rounded-full transition-all duration-500 ease-out"
            style={{ width: `${progressWidth}%` }}
          ></div>
          <div className="absolute inset-0 flex items-center justify-between px-4 text-xs lg:text-sm text-gray-700">
            <span>KES {minPrice || minRange}</span>
            <span>KES {maxPrice || maxRange}</span>
          </div>
        </div>
      </div>

      {/* Bottom Gradient Effect */}
      <div className="absolute inset-0 pointer-events-none bg-gradient-to-t from-white via-transparent to-transparent opacity-70"></div>
    </div>
  );
};

export default PriceFilter;
