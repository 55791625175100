import React from "react";
import { MdCall } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

const SpecialCase = () => {
  const handleCall = () => {
    window.open("tel:+254713974672", "_self");
  };

  return (
    <div className="fixed bottom-24 right-2 z-20 flex flex-col items-end space-y-4">
      {/* Call Button */}
      <div className="flex items-center">
        <span className="bg-white p-1 rounded-lg shadow-md text-sm text-black mr-2">
          Call us today
        </span>
        <div
          onClick={handleCall}
          aria-label="Call"
          className="bg-green-500 w-12 h-12 rounded-full flex justify-center items-center shadow-md cursor-pointer animate-glow"
        >
          <MdCall className="text-2xl text-white" />
        </div>
      </div>

      {/* WhatsApp Button */}
      <div className="flex items-center">
        <span className="bg-white p-1 rounded-lg shadow-md text-sm text-black mr-2">
          Need help? <b>Chat with us</b>
        </span>
        <a
          href="https://wa.me/0711304993"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="WhatsApp"
          className="bg-green-500 w-12 h-12 rounded-full flex justify-center items-center shadow-md cursor-pointer animate-glow"
        >
          <FaWhatsapp className="text-2xl text-white" />
        </a>
      </div>
    </div>
  );
};

export default SpecialCase;
