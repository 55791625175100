import React, { useState } from "react";
import { Modal, Button } from "@mui/material";
import newsletter from "../../assets/landing/newsletter.png";
import axios from "axios";
import { notification } from "antd";

import { Link } from "react-router-dom";

const BannerBottom = () => {
  const [open, setOpen] = useState(false);
  const [emailInfo, setEmailInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [subscription, setSubscription] = useState(false);

  const links = [
    // { href: "/membership", text: "Join Aztech club" },
    { href: "/about-us", text: "About Us" },
    // { href: "/aztechteam", text: "Blogs" },
    { href: "/faqs", text: "FAQ" },
    { href: "/contact", text: "Contact" },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const emailValidation = () => {
    return String(emailInfo)
      .toLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubscribe = async () => {
    if (emailInfo === "") {
      notification.error({
        message: "Error",
        description: "Please provide your email address!",
        placement: "topRight",
      });
      return;
    } else if (!emailValidation(emailInfo)) {
      notification.error({
        message: "Error",
        description: "Please provide a valid email address!",
        placement: "topRight",
      });
      return;
    }
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/customers/news`,
        {
          email: emailInfo,
        }
      );
      setSubscription(true);
      setEmailInfo("");
      notification.success({
        message: "Subscription Successful",
        description: "You've successfully subscribed to our newsletter!",
        placement: "topRight",
      });
    } catch (error) {
      console.error("Error subscribing:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Error subscribing. Please try again later.";
      notification.error({
        message: "Subscription Error",
        description: errorMessage,
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-purple-950 text-white px-10 py-0 sticky top-0 z-50">
        <nav className="h-full px-4 py-4 max-w-container mx-auto hidden md:flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-sm font-bold">
              Free Delivery For orders above 2,500/=.
            </span>
          </div>
          <div className="flex items-center space-x-8">
            {links.map((link, index) => (
              <Link
                key={index}
                className="text-sm border-r pr-4"
                to={link.href}
                aria-label={link.text}
              >
                {link.text}
              </Link>
            ))}
            <button className="text-sm " onClick={() => setOpen(true)}>
              Newsletter
            </button>
          </div>
        </nav>
      </div>

      {/* Modal for Newsletter Subscription */}
      <Modal open={open} onClose={handleClose}>
        <div className="flex justify-center items-center w-full h-full p-5 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg md:flex md:w-3/4 lg:w-2/3 relative overflow-hidden">
            <div className="md:w-1/2 p-0 flex flex-col items-center justify-center bg-[orange]">
              <img
                src={newsletter}
                alt="Newsletter Benefits"
                className="w-full h-auto md:h-[500px] mb-0"
                style={{ objectFit: "cover", objectPosition: "center" }}
              />
            </div>
            <div className="md:w-1/2 p-5 flex flex-col justify-between h-auto md:h-[500px]">
              <h2 className="text-2xl md:text-4xl font-bold mb-4 md:mb-2 text-green-500">
                Join Our Newsletter!
              </h2>
              <p className="mb-4 text-gray-700">
                Subscribe to receive the latest updates, exclusive offers, and
                insightful content.
              </p>
              <ul className="list-disc list-inside mb-4 text-gray-700">
                <li>Stay updated with the latest news and offers.</li>
                <li>Exclusive discounts and promotions.</li>
                <li>Get tips and insights delivered directly to your inbox.</li>
                <li>Be the first to know about new products.</li>
              </ul>
              <div>
                <input
                  placeholder="Your Email"
                  type="email"
                  onChange={(e) => setEmailInfo(e.target.value)}
                  value={emailInfo}
                  required
                  className="mb-4 w-full rounded px-2 py-2 text-green border border-1 border-green-500 outline-none"
                />
                <div className="w-full flex items-center gap-4 py-4">
                  <button
                    onClick={handleClose}
                    className="w-full px-2 py-2 rounded bg-pink-700 text-white text-lg font-semibold"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSubscribe}
                    disabled={loading}
                    className={`w-full px-2 py-2 rounded bg-green-500 text-white text-lg font-semibold ${
                      loading ? "bg-gray-500" : "hover:bg-green-600"
                    } duration-300 text-base tracking-wide`}
                  >
                    {loading ? "Subscribing..." : "Subscribe"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BannerBottom;
