import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSession from "../../context/useSession";

const UpdatePriceModal = ({ open, onClose, product, onPriceUpdate }) => {
  const [newPrice, setNewPrice] = useState("");
  const [count, setCount] = useState(""); 
  const [loading, setLoading] = useState(false);
  const { authToken } = useSession();

  const productId = product?.id;

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setLoading(true);
    try {
      const parsedCount = parseInt(count, 10);
      const parsedPrice = parseFloat(newPrice);
      if (isNaN(parsedCount) || isNaN(parsedPrice)) {
        toast.error("Please enter valid price and count");
        setLoading(false);
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/product_price_update`,
        {
          productId,
          newPrice: parsedPrice,
          newCount: parsedCount,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Product updated successfully");
      onPriceUpdate();
      onClose();
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("Failed to update product");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96">
            <h2 className="text-center text-2xl font-semibold text-gray-800 mb-4">
              Update Product
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="form-group">
                <label
                  htmlFor="newPrice"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  New Price
                </label>
                <input
                  type="number"
                  id="newPrice"
                  value={newPrice}
                  onChange={(e) => setNewPrice(e.target.value)}
                  placeholder="Enter new price"
                  className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="count"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Count
                </label>
                <input
                  type="number"
                  id="count"
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                  placeholder="Enter count"
                  className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  className="bg-red-600 text-white font-bold py-2 px-4 rounded-md hover:bg-red-700"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`bg-blue-600 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-700 ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default UpdatePriceModal;
