import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import "./index.css";
import App from "./App";
import { SessionProvider } from "./context/SessionContext";
import { CartProvider } from "./context/cart/CartProvider ";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SessionProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CartProvider>
          <App />
        </CartProvider>
      </PersistGate>
    </Provider>
  </SessionProvider>
);
