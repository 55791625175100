import React from "react";

const ShopNow = () => {
  return (
    <button className="bg-green-700 text-white text-lg font-bodyFont w-[185px] h-[50px] group-hover:bg-green-800 group-hover:rotate-1 duration-300 font-bold rounded">
      Shop Now
    </button>
  );
};

export default ShopNow;
