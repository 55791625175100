import React from "react";
import AdminDash from "../../Components/Dashboard/AdminDash";

const Home = () => {
  return (
    <div>
      <AdminDash />
    </div>
  );
};

export default Home;
