import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const ForgotPassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState(""); // No default method initially

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!email && !phoneNumber) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter both the email and phone number.",
      });
      return;
    }
    if (
      deliveryMethod === "" ||
      (deliveryMethod === "email" && !email) ||
      (deliveryMethod === "sms" && !phoneNumber)
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Please enter your ${
          deliveryMethod === "email" ? "email" : "phone number"
        }.`,
      });
      return;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/auth/generate_otp`,
        {
          email: email,
          phoneNumber: phoneNumber,
          deliveryMethod,
        }
      );
      setStep(2);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "OTP sent successfully.",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to send OTP. Please provide your email and phone number.",
      });
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/auth/reset_pass`,
        {
          email,
          otp,
          newPassword,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Password reset successful.",
      });
      setEmail("");
      setPhoneNumber("");
      setOtp("");
      setNewPassword("");
      setStep(1);
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to reset password. Please check your OTP and try again.",
      });
    }
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-r from-slate-100 via-slate-200 to-slate-300 items-center justify-center p-2 md:p-2 lg:p-2 xl:p-2">
      <div className="flex flex-col md:flex-row w-full max-w-5xl bg-white rounded-lg shadow-2xl overflow-hidden">
        <div className="md:w-1/2 p-10 bg-gradient-to-br from-blue-700 to-blue-800 text-white flex flex-col justify-center">
          <h1 className="text-xl  md:text-4xl lg:text-4xl xl:text-4xl font-extrabold mb-6 leading-tight">
            Forgot Your Password?
          </h1>
          <p className="text-lg mb-6 leading-relaxed">
            No worries! Just follow these simple steps to reset your password.
            We’ll have you back on track in no time.
          </p>
          <ul className="list-disc list-inside mb-6 space-y-3 text-lg">
            <li>Enter your email and/or phone number.</li>
            <li>Select your preferred method to receive the OTP.</li>
            <li>Enter the OTP and your new password to reset.</li>
          </ul>
          <div className="mt-4">
            <p className="text-lg font-semibold">Need Assistance?</p>
            <p className="text-base">
              Reach out to our support team at{" "}
              <a
                href="mailto:support@vintaraafrica.com"
                className="text-yellow-400 underline"
              >
                support@vintaraafrica.com
              </a>
            </p>
          </div>
        </div>
        <div className="md:w-1/2 p-10 bg-white flex flex-col justify-center">
          {step === 1 && (
            <form onSubmit={handleSendOtp} className="space-y-8">
              <h2 className="text-3xl font-semibold text-gray-800 mb-4">
                Reset Your Password
              </h2>
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-lg mb-2"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-5 py-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                  placeholder="Enter your email"
                />
              </div>
              <div>
                <label
                  htmlFor="phoneNumber"
                  className="block text-gray-700 text-lg mb-2"
                >
                  Phone Number:
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="w-full px-5 py-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="mb-8">
                <p className="block text-gray-700 text-lg mb-2">
                  Delivery Method:
                </p>
                <div className="flex space-x-4">
                  <div
                    onClick={() => setDeliveryMethod("email")}
                    className={`w-full p-4 border rounded-lg cursor-pointer text-center transition duration-300 ease-in-out ${
                      deliveryMethod === "email"
                        ? "bg-blue-600 text-white border-blue-700"
                        : "bg-white text-gray-800 border-gray-300 hover:bg-gray-100"
                    }`}
                  >
                    <h3 className="text-xl font-semibold mb-2">Email</h3>
                    <p>Receive OTP via email.</p>
                  </div>
                  <div
                    onClick={() => setDeliveryMethod("sms")}
                    className={`w-full p-4 border rounded-lg cursor-pointer text-center transition duration-300 ease-in-out ${
                      deliveryMethod === "sms"
                        ? "bg-blue-600 text-white border-blue-700"
                        : "bg-white text-gray-800 border-gray-300 hover:bg-gray-100"
                    }`}
                  >
                    <h3 className="text-xl font-semibold mb-2">SMS</h3>
                    <p>Receive OTP via SMS.</p>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-700 to-blue-800 text-white py-4 rounded-lg hover:bg-gradient-to-l hover:from-blue-800 hover:to-blue-900 transition duration-300"
              >
                Send OTP
              </button>
            </form>
          )}
          {step === 2 && (
            <form onSubmit={handleResetPassword} className="space-y-8">
              <h2 className="text-3xl font-semibold text-gray-800 mb-4">
                Enter OTP and New Password
              </h2>
              <div>
                <label
                  htmlFor="otp"
                  className="block text-gray-700 text-lg mb-2"
                >
                  OTP:
                </label>
                <input
                  type="text"
                  id="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                  className="w-full px-5 py-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                  placeholder="Enter OTP"
                />
              </div>
              <div>
                <label
                  htmlFor="newPassword"
                  className="block text-gray-700 text-lg mb-2"
                >
                  New Password:
                </label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  className="w-full px-5 py-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                  placeholder="Enter new password"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-indigo-600 to-purple-700 text-white py-4 rounded-lg hover:bg-gradient-to-l hover:from-purple-700 hover:to-indigo-600 transition duration-300"
              >
                Reset Password
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
