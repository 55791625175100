import React from "react";
import { FaTruck, FaGlobe, FaHeadset, FaCheckCircle, FaCreditCard } from "react-icons/fa"; // Import icons from react-icons

const Disclaimer = () => {
  return (
    <div className="max-w-8xl hidden md:flex mx-auto px-4 py-4">
      <div className="flex items-center justify-between gap-8 mt-6 mb-6 shadow-lg p-6 bg-white rounded-lg">
        
        <div className="flex items-center gap-4">
          <FaTruck className="text-purple-800 text-2xl" />
          <div className="flex flex-col">
            <p className="text-purple-800 font-semibold text-lg">Express Delivery</p>
            <span className="text-sm text-gray-600">Same day delivery if ordered by 7 pm</span>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <FaGlobe className="text-purple-800 text-2xl" />
          <div className="flex flex-col">
            <p className="text-purple-800 font-semibold text-lg">Nationwide Delivery</p>
            <span className="text-sm text-gray-600">From a network of over 140+ stores.</span>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <FaHeadset className="text-purple-800 text-2xl" />
          <div className="flex flex-col">
            <p className="text-purple-800 font-semibold text-lg">Customer Support</p>
            <span className="text-sm text-gray-600">Available 7 days a week.</span>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <FaCheckCircle className="text-purple-800 text-2xl" />
          <div className="flex flex-col">
            <p className="text-purple-800 font-semibold text-lg">Genuine Products</p>
            <span className="text-sm text-gray-600">All our products are 100% genuine.</span>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <FaCreditCard className="text-purple-800 text-2xl" />
          <div className="flex flex-col">
            <p className="text-purple-800 font-semibold text-lg">Easy Payments</p>
            <span className="text-sm text-gray-600">Pay by Mpesa, Visa, or MasterCard.</span>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Disclaimer;
