import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ImCross } from "react-icons/im";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { toast } from "react-toastify";
import { emptyCart } from "../../assets/images/index";
import useSession from "../../context/useSession";
import { CartContext } from "../../context/cart/CartProvider ";
import ContactBottom from "../../components/home/Header/Components/ContactBottom";

const Cart = () => {
  const navigate = useNavigate();

  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const { authToken } = useSession();
  const {
    cartItems,
    removeItemFromCart,
    clearCart,
    increaseQuantity,
    decreaseQuantity,
  } = useContext(CartContext);

  useEffect(() => {
    const calculateTotal = () => {
      const total = cartItems.reduce((sum, item) => sum + item.itemTotal, 0);
      setTotalAmt(total);
    };
    calculateTotal();
  }, [cartItems]);

  useEffect(() => {
    if (totalAmt <= 200) {
      setShippingCharge(150);
    } else if (totalAmt <= 400) {
      setShippingCharge(25);
    } else if (totalAmt >= 401) {
      setShippingCharge(200);
    }
  }, [totalAmt]);

  const handleClearCart = async () => {
    try {
      await clearCart();
      toast.success("Cart cleared successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error clearing cart:", error.response?.data?.message || error.message);
      toast.error(error.response?.data?.message || "Failed to clear the cart.");
    }
  };

  const handleItemDelete = async (itemId) => {
    try {
      await removeItemFromCart(itemId);
      toast.success("Item removed from cart successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting item:", error.response?.data?.message || error.message);
      toast.error(error.response?.data?.message || "Failed to delete item.");
    }
  };

  const handleIncrease = async (itemId) => {
    try {
      await increaseQuantity(itemId);
      toast.success("Quantity increased");
    } catch (error) {
      toast.error("Failed to increase quantity.");
    }
  };

  const handleDecrease = async (itemId, currentQuantity) => {
    if (currentQuantity > 1) {
      try {
        await decreaseQuantity(itemId);
        toast.success("Quantity decreased");
      } catch (error) {
        toast.error("Failed to decrease quantity.");
      }
    } else {
      toast.info("Item quantity cannot go below 1.");
    }
  };

  const handleProceedToCheckout = () => {
    const totalAmount = totalAmt;
    navigate(`/payment_gateway?totalAmount=${encodeURIComponent(totalAmount)}`);
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Cart" />
      {cartItems.length > 0 ? (
        <div className="pb-20">
          <div className="w-full h-20 bg-[#F5F7F7] text-primeColor hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg font-titleFont font-semibold">
            <h2 className="col-span-2">Product</h2>
            <h2>Price</h2>
            <h2>Quantity</h2>
            <h2>Sub Total</h2>
          </div>

          <div className="mt-5">
            {cartItems.map((item) => (
              <div key={item.id} className="w-full grid grid-cols-5 mb-4 border py-2">
                <div className="flex col-span-5 mdl:col-span-2 items-center gap-4 ml-4">
                  <ImCross
                    onClick={() => handleItemDelete(item.id)}
                    className="text-primeColor hover:text-pink-600 duration-300 cursor-pointer"
                  />
                  <img
                    className="w-32 h-32"
                    src={item.product.images[0].url}
                    alt={`${item.product.name} productImage`}
                  />
                  <div>
                    <h1 className="font-titleFont font-semibold">
                      {item.product.name}
                    </h1>
                  </div>
                </div>
                <div className="col-span-5 mdl:col-span-3 flex items-center justify-between py-4 mdl:py-0 px-4 mdl:px-0 gap-6 mdl:gap-0">
                  <div className="flex w-1/3 items-center text-sm font-semibold">
                    KES{item.price}
                  </div>
                  <div className="w-1/3 flex items-center gap-2 text-lg">
                    <span
                      onClick={() => handleDecrease(item.id, item.count)}
                      className="w-6 h-6 bg-gray-100 text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300 hover:border-gray-300"
                    >
                      -
                    </span>
                    <input
                      type="number"
                      value={item.count}
                      min="1"
                      className="w-10 text-center border border-gray-300 rounded flex items-center justify-center py-1"
                      disabled
                    />
                    <span
                      onClick={() => handleIncrease(item.id)}
                      className="w-6 h-6 bg-gray-200 text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300 hover:border-gray-300"
                    >
                      +
                    </span>
                  </div>
                  <div className="w-1/3 flex items-center font-titleFont font-bold text-sm">
                    <p>KES{item.itemTotal}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <button
            onClick={handleClearCart}
            className="py-2 px-10 bg-pink-600 text-white font-semibold uppercase mb-4 hover:bg-pink-700 transition-all duration-300 rounded-md"
          >
            Reset cart
          </button>

          <div className="flex flex-col mdl:flex-row justify-between border py-4 px-4 items-center gap-2 mdl:gap-0">
            <div className="flex items-center gap-4">
              <input
                className="w-44 mdl:w-52 h-8 px-4 border text-primeColor text-sm outline-none border-gray-400"
                type="text"
                placeholder="Coupon Number"
                disabled
              />
              <p className="text-sm mdl:text-base font-semibold">Apply Coupon</p>
            </div>
            <p className="text-lg font-semibold">Update Cart</p>
          </div>
          <div className="max-w-7xl gap-4 flex justify-end mt-4">
            <div className="w-96 flex flex-col gap-4">
              <h1 className="text-2xl font-semibold text-right">Cart totals</h1>
              <div>
                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                  Subtotal
                  <span className="font-semibold tracking-wide font-titleFont">KES{totalAmt}</span>
                </p>
                <p className="flex items-center justify-between border-[1px] border-gray-400 py-1.5 text-lg px-4 font-semibold">
                  Total
                  <span className="text-lg font-bold tracking-wide text-primeColor">KES{totalAmt}</span>
                </p>
              </div>
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={handleProceedToCheckout}
                className="w-full text-center bg-purple-900 text-white py-3 font-titleFont uppercase font-medium hover:bg-purple-950 duration-300"
              >
                Proceed to checkout
              </motion.button>
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto"
              src={emptyCart}
              alt="emptyCart"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center">
            <h1 className="font-titleFont text-xl font-bold uppercase">Your Cart feels lonely.</h1>
            <p className="text-sm text-center px-10 -mt-2">
              Your Shopping cart lives to serve. Give it purpose – fill it with groceries, clothing, household
              essentials, electronics, and more.
            </p>
            <Link
              to="/"
              className="px-6 py-3 text-white bg-pink-600 uppercase font-semibold hover:bg-pink-700 transition-all duration-300"
            >
              Continue Shopping
            </Link>
          </div>
        </motion.div>
      )}
      <ContactBottom />
    </div>
  );
};

export default Cart;
