import React from "react";
import PriceFilter from "./shopBy/PriceFilter";
import ColorFilter from "./shopBy/ColorFilter";

const CategorySide = () => {
  return (
    <div className="w-full flex flex-col gap-6">
      {/* <ColorFilter /> */}
      <PriceFilter />
    </div>
  );
};

export default CategorySide;
