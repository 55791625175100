import React, { useState, useEffect } from "react";
import {
  FaDollarSign,
  FaBox,
  FaWarehouse,
  FaSearch,
} from "react-icons/fa";
import useSession from "../../../context/useSession";
import axios from "axios";
import moment from "moment";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const ProductsAdmin = () => {
  const { authToken } = useSession();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/get_products`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setProducts(response.data.products);
      } catch (error) {
        console.error("Error fetching products", error);
      }
    };

    fetchProducts();
  }, [authToken]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Stock Analysis Data
  const totalStock = products.reduce((sum, product) => sum + product.count, 0);
  const totalActiveStock = totalStock; // Assuming all are active since we have no sold data
  const totalStockValue = products.reduce(
    (sum, product) => sum + product.price * product.count,
    0
  );

  // Pie Chart Data
  const pieData = {
    labels: ["Active Stock"],
    datasets: [
      {
        data: [totalActiveStock],
        backgroundColor: ["#36A2EB"],
        hoverBackgroundColor: ["#36A2EB"],
      },
    ],
  };

  const pieOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <div className="container mx-auto px-0 md:px-4 pb-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8 pt-5">
        <div className="bg-slate-100 p-6 rounded-lg shadow-lg flex items-center justify-start space-x-5 py-8">
          <FaBox className="text-4xl text-blue-600" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">Total Products</h3>
            <p className="text-base font-normal text-gray-900">{products.length}</p>
          </div>
        </div>

        {/* Total Stock */}
        <div className="bg-slate-100 p-6 rounded-lg shadow-lg flex items-center justify-start space-x-5 py-8">
          <FaWarehouse className="text-4xl text-green-600" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">Total Stock</h3>
            <p className="text-base font-normal text-gray-900">{totalStock}</p>
          </div>
        </div>

        {/* Total Stock Value */}
        <div className="bg-slate-100 p-6 rounded-lg shadow-lg flex items-center justify-start space-x-5 py-8">
          <FaDollarSign className="text-4xl text-orange-600" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">Total Stock Value</h3>
            <p className="text-base font-normal text-gray-900">
              KES {totalStockValue.toFixed(0)}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-white shadow rounded-lg p-4 h-80">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Active Stock</h3>
          <Pie data={pieData} options={pieOptions} />
        </div>
      </div>

      <div className="flex items-end justify-end mb-4 mt-2 mr-5">
        <a
          href="/admin/register_item"
          className="bg-orange-600 text-white text-bold rounded p-2"
        >
          Add product
        </a>
      </div>

      <div className="mb-4 flex items-center">
        <div className="relative w-full md:w-72 max-w-d shadow-lg">
          <input
            type="text"
            placeholder="Search products..."
            className="w-full p-2 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-orange-600 outline-1"
            value={searchTerm}
            onChange={handleSearch}
          />
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
        </div>
      </div>

      {/* Products Table */}
      <div className="overflow-x-auto shadow-lg">
        <table className="min-w-full divide-y divide-gray-200 bg-[#F5F5F3] shadow-lg rounded-md">
          <thead className="bg-[#F5F5F3]">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Image</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Product Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Price</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Stock</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Created At</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentItems
              .filter((product) => product.name.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((product) => (
                <tr key={product.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <img src={product?.images[0]?.imageUrl} alt={product.name} className="h-12 w-12 object-cover rounded-md" />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{product.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{`KES ${product.price.toFixed(0)}`}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{product.count}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{moment(product.createdAt).format("MMM D, YYYY")}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                    <a href={`/admin/product/${product.id}`} className="text-blue-600 hover:underline">Edit</a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between mt-4">
        <button
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-orange-600 text-white rounded-lg"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-orange-600 text-white rounded-lg"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProductsAdmin;
