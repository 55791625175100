import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Breadcrumbs from "../pageProps/Breadcrumbs";
import useSession from "../../context/useSession";
import { useDispatch } from "react-redux";
import { FaStar } from "react-icons/fa";
import AddToCartModal from "./AddToCartModal";
import UpdatePriceModal from "./UpdatePriceModal";
import { motion } from "framer-motion";
import Products from "../home/AllProducts/Products";
import ContactBottom from "../home/Header/Components/ContactBottom";
import { BsSuitHeartFill } from "react-icons/bs";
import { toast } from "react-toastify";
import AddCart from "./AddCart";

const tabs = [
  { id: "Description", label: "Description" },
  { id: "Delivery", label: "Delivery and Returns" },
  { id: "Reviews", label: "Reviews" },
];

const ProductPage = (props) => {
  const { id } = useParams();
  const productId = id;
  const { authToken, user } = useSession();

  const [rating, setRating] = useState(0);
  const [avgRating, setAvgRating] = useState({});
  const [product, setProductDetails] = useState({});
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedColors, setSelectedColors] = useState([]);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wishList, setWishList] = useState([]);

  const { _id } = props;

  const handleAddToCartClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleClick = (value) => {
    setRating(value);
    submitRating(value);
  };

  const handleWishList = () => {
    toast.success("Product added to wishlist");
    setWishList([...wishList, props]);
  };

  const handleRemoveFromWishList = () => {
    const updatedWishList = wishList.filter((item) => item._id !== props._id);
    setWishList(updatedWishList);
    toast.info("Product removed from wishlist");
  };

  const isInWishlist = (id) => wishList.some((item) => item._id === id);

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/get_product/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const productData = response.data.product;
      setProductDetails(productData);
      if (productData.images.length > 0) {
        setSelectedImage(productData.images[0].imageUrl);
      }
    } catch (error) {
      console.error("Error fetching product", error);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [authToken, id]);

  const handlePriceUpdate = () => {
    fetchProductDetails();
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [avgRatingResponse] = await Promise.all([fetchAvgRating()]);
      setAvgRating(avgRatingResponse.data.averageRating);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAvgRating = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/avg_ratings/${productId}`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  // Submitting a rating
  const submitRating = async (rating) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/ratings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            userId: user.id,
            productId: +id,
            rate: rating,
          }),
        }
      );

      if (response.ok) {
        console.log("Rating submitted successfully");
        fetchData();
      } else {
        console.log("Failed to submit rating");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderDescription = () => (
    <div className="flex flex-col gap-4">
      <p className="text-base text-gray-600">
        {product.desc || "No description available"}
      </p>
      {product.instructions ? (
        <>
          <p className="font-semibold text-purple-900 text-base">
            Usage instructions:
          </p>
          <span>{product.instructions}</span>
        </>
      ) : null}{" "}
    </div>
  );

  const renderDeliveryInsights = () => {
    return (
      <div className="flex flex-col items-start justify-start  gap-3">
        <div className="flex flex-col items-start justify-start gap-2">
          <h1 className="text-purple-900 font-semibold text-base">
            What delivery options are available ?
          </h1>
          <p className="text-sm text-gray-600">
            When checking out you will able to select what delivery options you
            prefer,You can either collect your order instore from the nearest
            branch, or your order can be delivered to your nominated location by
            our approved delivery partner.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start gap-2">
          <h1 className="text-purple-900 font-semibold text-base">
            How much is the delivery cost?
          </h1>
          <p className="text-sm text-gray-600">
            The delivery charges are calculated based on your location. This is
            displayed on the summary page before you make payment.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start gap-2">
          <h1 className="text-purple-900 font-semibold text-base">
            How can I track my order?
          </h1>
          <p className="text-sm text-gray-600">
            As soon as your order is dispatched from the store, you’ll be
            notified via Email. However, you can also call 0711 000 000
          </p>
        </div>
        <div className="flex flex-col items-start justify-start gap-2">
          <h1 className="text-purple-900 font-semibold text-base">
            Can I send someone to collect my order on my behalf ?
          </h1>
          <p className="text-sm text-gray-600">
            For in-store pick-up, we will only release the order to a third
            party upon confirmation from you through our various communication
            channels that someone is collecting for you, or if we have
            proof(order notification email) that the individual is picking it up
            on your behalf.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start gap-2">
          <h1 className="text-purple-900 font-semibold text-base">
            How long does delivery take after ordering ?
          </h1>
          <p className="text-sm text-gray-600">
            Your purchase will be delivered within 3 hours , if you make your
            order between 8:00 am and 6.00 p.m, Monday to Sunday. If you
            purchase after 6.00 p.m delivery will be done the next day and In
            the event we face challenges with delivery, you will be notified at
            the earliest time possible
          </p>
        </div>
        <div className="flex flex-col items-start justify-start gap-2">
          <h1 className="text-purple-900 font-semibold text-base">
            Can I return an item?
          </h1>
          <p className="text-sm text-gray-600">
            Please refer to our returns policy.
          </p>
        </div>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div>
        <h3 className="text-lg font-semibold text-purple-900">There are no reviews yet.</h3>
        <div className="flex flex-wrap gap-4 mt-2">
          Only logged in customers who have purchased this product may leave a
          review.
        </div>
      </div>
    );
  };

  const renderWishListButtons = () => {
    if (isInWishlist(props._id)) {
      return (
        <div className="flex gap-4">
          <button
            onClick={handleRemoveFromWishList}
            className="flex items-center gap-2"
          >
            <BsSuitHeartFill className="text-pink-600 hover:text-pink-800 duration-300 ease-in-out transition-all h-6 w-6" />
            <p className="text-sm text-red-600 font-medium hover:underline">
              Remove from Wishlist
            </p>
          </button>
          <button
            onClick={() => console.log("View product details")}
            className="flex items-center gap-2"
          >
            <BsSuitHeartFill className="text-blue-600 hover:text-blue-800 duration-300 ease-in-out transition-all h-6 w-6" />
            <p className="text-sm text-blue-600 font-medium hover:underline">
              View
            </p>
          </button>
        </div>
      );
    } else {
      return (
        <button onClick={handleWishList} className="flex items-center gap-2">
          <BsSuitHeartFill className="text-gray-300 duration-300 ease-in-out transition-all h-6 w-6" />
          <p className="text-sm text-purple-900 font-medium hover:underline">
            Add to Wishlist
          </p>
        </button>
      );
    }
  };

  return (
    <motion.div
      className="w-full mx-auto border-b-[1px] border-b-gray-300 bg-white"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={window.location.pathname} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 p-4">
          <div className="h-full xl:col-span-2">
            <div className="xl:col-span-2">
              {selectedImage && (
                <img
                  className="w-full h-96 object-contain"
                  src={selectedImage}
                  alt={product.name}
                />
              )}
              <div className="flex mt-2 gap-2">
                {product.images?.map((img) => (
                  <img
                    key={img.id}
                    className="w-20 h-20 object-contain cursor-pointer border-2 hover:border-blue-500"
                    src={img.imageUrl}
                    alt={`Thumbnail ${img.id}`}
                    onClick={() => setSelectedImage(img.imageUrl)}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
            <div className="flex flex-col gap-5">
              <h2 className="text-2xl mdl:text-4xl font-semibold text-purple-900">
                {product.name}{" "}
                <span className="text-lg text-pink-600 ml-4">
                  ({Number(avgRating).toFixed(1)}{" "}
                  <FaStar className="inline text-sm" />)
                </span>
              </h2>
              <p className="text-2xl font-semibold text-purple-900">
                {(() => {
                  const priceTracker = product.priceTracker;
                  if (priceTracker && priceTracker.status === "decrease") {
                    const percentageOff =
                      ((priceTracker.previousPrice -
                        priceTracker.currentPrice) /
                        priceTracker.previousPrice) *
                      100;

                    return (
                      <>
                        <span className="line-through text-sm font-semibold text-pink-600">
                          Ksh {priceTracker.previousPrice.toLocaleString()}
                        </span>
                        <span className="ml-2">
                          Ksh {priceTracker.currentPrice.toLocaleString()}
                        </span>
                        <span className="text-xs ml-2 inline-flex items-center px-3 py-1 rounded-full bg-purple-900 text-white">
                          {`Save Ksh ${priceTracker.changeAmount.toLocaleString()} (${percentageOff.toFixed(
                            0
                          )}% off)`}
                        </span>
                      </>
                    );
                  } else if (product.price) {
                    return <span>Ksh {product.price.toLocaleString()}</span>;
                  } else {
                    return "N/A";
                  }
                })()}
              </p>

              <hr />

              {renderDescription()}

              {user.isUser === true && (
                <div className="mt-4">{renderWishListButtons()}</div>
              )}

              <div className="flex items-center">
                <p className="text-sm mr-2">Leave a rating:</p>
                {[1, 2, 3, 4, 5].map((star) => (
                  <svg
                    key={star}
                    className={`w-4 h-4 ms-1 ${
                      star <= rating ? "text-pink-600" : "text-gray-300"
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                    onClick={() => handleClick(star)}
                    style={{ cursor: "pointer" }}
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                ))}
              </div>

              <div className="flex gap-2">
                <p
                  className={`text-base font-bold ${
                    product.count > 0 ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {product.count > 0
                    ? `${product.count} In stock`
                    : "Out of stock"}
                </p>
              </div>

              {user.isUser === false && (
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="p-2 bg-blue-500 text-white rounded-md"
                >
                  Update Price
                </button>
              )}

              <UpdatePriceModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                product={product}
                onPriceUpdate={handlePriceUpdate}
              />
              {user.isUser === true && (
                <AddCart
                  show={showModal}
                  handleClose={handleCloseModal}
                  product={product}
                />
              )}
            </div>
          </div>
        </div>
        <div className="w-full bg-white  mb-8">
          <div className="max-w-container mx-auto">
            <div className="tabs mt-6 flex border-b border-gray-300">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => handleTabClick(tab.id)}
                  className={`px-4 py-2 font-medium text-purple-900 ${
                    activeTab === tab.id
                      ? "border-b-2 border-purple-900 text-purple-900"
                      : ""
                  }`}
                >
                  {tab.label}
                </button>
              ))}
            </div>
            <div className="p-6">
              {activeTab === "Delivery" && renderDeliveryInsights()}
              {activeTab === "Reviews" && renderReviews()}
              {activeTab === "Description" && renderDescription()}
            </div>
          </div>
        </div>

        <div className="pt-3 mdl:pt-3">
          <Products />
        </div>
        <div>
          <ContactBottom />
        </div>
      </div>
    </motion.div>
  );
};

export default ProductPage;
